import React, { useState } from "react";
import SearchBox from "src/components/SearchBox";
import { Table, Card, Row, Col, Button } from "antd";
import { ApiGet } from "src/util/ApiClient";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setCurrentRoutes } from "src/appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useApiErrorAlert } from "src/hooks/user";
import { IPagination, IPartnerModel } from "src/type-interface/model";
import { history } from "src/appRedux/store";

interface IFilter {
  keywords: string;
}

const columns = [
  {
    title: "KODE PARTNER",
    dataIndex: "partner_code",
    key: "partner_code",
    width: 100,
    render: (val: string) => <Link to={`/partner/${val}`}>{val}</Link>,
  },
  {
    title: "NAMA",
    dataIndex: "name",
    key: "name",
    width: 120,
    render: (val: string) => <span>{val}</span>,
  },
  {
    title: "DOMAIN",
    dataIndex: "domain",
    key: "domain",
    width: 130,
    render: (val: string) => <span>{val}</span>,
  },
  {
    title: "SHORT NAME",
    dataIndex: "partner_short_name",
    key: "partner_short_name",
    width: 100,
    render: (val: string) => <span>{val}</span>,
  },
  {
    title: "LONG NAME",
    dataIndex: "partner_long_name",
    key: "partner_long_name",
    width: 120,
    render: (val: string) => <span>{val}</span>,
  },
  // {
  //   title: "LOGO",
  //   dataIndex: "partner_code",
  //   key: "partner_code",
  //   width: 80,
  //   align: "center" as const,
  //   render: (val: string, row: IPartnerModel) => <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5em" }}><img src={row.logo_square_url} /><img src={row.logo_rectangular_url} /></div>,
  // },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    width: 80,
    align: "center" as const,
    render: (val: string) => <span>{val === "active" ? "Aktif" : "Tidak Aktif"}</span>,
  }
];

export const PartnerListPage: React.FC<any> = (props) => {

  const [partnerList, setPartnerList] = useState<Array<IPartnerModel>>([]);
  const [filter, setFilter] = React.useState<IFilter>({ keywords: "" });
  const [pagination, setPagination] = React.useState<IPagination>({});

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiErrorAlert = useApiErrorAlert();

  const { authUser } = useSelector(({ auth }: { auth: any }) => auth);

  const onChangeFilter = React.useCallback((key: string, value: string) => {
    const newFilter = { ...filter, [key]: value };
    loadPartnerList(getQueryString(undefined, newFilter));
  }, [filter, pagination]);

  const getQueryParams = React.useCallback((query?: string) => {
    const queryParams = new URLSearchParams(query || location.search);
    let params: any = {};
    queryParams.forEach((v, k) => params[k] = v);
    return params;
  }, [location]);

  const getQueryString = React.useCallback((newPagination?: IPagination, newFilter?: any) => {
    return ("?" + new URLSearchParams({
      ...(newPagination || pagination),
      ...(newFilter || filter)
    }).toString());
  }, [filter, pagination]);

  const loadPartnerList = React.useCallback(async (query: string) => {
    const res = await ApiGet(`/partner/list${query || ""}`);
    if (res.data && Array.isArray(res.data.data)) {
      setPartnerList([...res.data.data]);
      const resPagination = res.data.pagination;
      const newQuery = getQueryParams(query);
      const newFilter = { ...filter, keywords: newQuery.keywords || "" };
      const newPagination = { perPage: resPagination.perPage, currentPage: resPagination.currentPage, total: resPagination.total };
      const url = getQueryString(newPagination, newFilter);
      setPagination(newPagination);
      setFilter(newFilter);
      history.replace(`/partner${url}`, null);
    } else if (!res.success) {
      apiErrorAlert(res);
    }
  }, [partnerList, filter, pagination]);

  React.useEffect(() => {
    loadPartnerList(location.search);
    dispatch(setCurrentRoutes([
      {
        breadcrumbName: "Master",
      },
      {
        path: "/partner",
        breadcrumbName: "Daftar Mitra",
      }
    ]));
  }, [location.search]);

  return (
    <Row style={{ rowGap: 0 }}>
      <Col className="ant-col-24">
        <Card title={
          <Row>
            <Col className="ant-col-18">
              <div style={{ display: "flex", flexDirection: "row", paddingTop: 12, paddingLeft: 6 }}>
                <div style={{ display: "flex", flexDirection: "column", width: 300 }}>
                  <label style={{ height: 32 }}>Pencarian</label>
                  <SearchBox
                    placeholder="Kata Kunci..."
                    onChange={(e: any) => onChangeFilter("keywords", e.target.value)}
                    value={filter.keywords} />
                </div>
              </div>
            </Col>
            {
              authUser?.type === "root" &&
              <Col className="ant-col-6 text-right">
                <Button type="primary" onClick={() => {
                  navigate(`/partner/new`, { state: { from: history.location.pathname+history.location.search } });
                }}>Tambah Mitra</Button>
              </Col>
            }
          </Row>
        }>
          <Table
            className="gx-table-responsive"
            columns={columns}
            dataSource={partnerList}
            pagination={{
              pageSize: Number(pagination.perPage),
              total: Number(pagination.total),
              defaultCurrent: 0,
              defaultPageSize: 10,
              current: Number(pagination.currentPage),
              showSizeChanger: true
            }}
            tableLayout="auto"
            scroll={{ y: 425, x: "max-content" }}
            onChange={(valPagination: any, filter) => {
              const newPagination = {
                perPage: Number(valPagination.pageSize),
                currentPage: Number(valPagination.current)
              };
              navigate(`/partner${getQueryString(newPagination)}`);
            }}
            onRow={(record, index) => {
              return {
                onClick: () => {
                  navigate(`/partner/${record.partner_code}`, { state: { from: location.pathname + location.search } });
                }
              }
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};