import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Auth from "./Auth";
import Settings from "./Settings";
import System from "./System";
import Common from "./CommonReducer";
import MasterDataReducer from "./MasterData";

const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  auth: Auth,
  common: Common,
  settings: Settings,
  system: System,
  masterData: MasterDataReducer
});

export default createRootReducer;
