import React, { useState } from "react";
import SearchBox from "src/components/SearchBox";
import SelectOption, { SelectOptionProps } from "src/components/SelectOption";
import { Table, Card, Row, Col, Button } from "antd";
import { ApiGet } from "src/util/ApiClient";
import { formatRupiah } from "src/util/Formater";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setCurrentRoutes } from "src/appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useApiErrorAlert } from "src/hooks/user";
import { IPagination, IServiceModel } from "src/type-interface/model";
import { ServiceGroupSelect, ServiceTypeMap, ServiceTypeSelect } from "src/components/Shared/SelectComponents";
import { history } from "src/appRedux/store";

interface IFilter {
  keywords: string;
  service_group_code: string;
  service_type: string;
}

const columns = [
  {
    title: "KODE LAYANAN",
    dataIndex: "service_code",
    key: "service_code",
    width: 90,
    render: (val: any, row: IServiceModel) => <Link to={`/service/${row.service_id}`}>{val}</Link>,
  },
  {
    title: "TIPE LAYANAN",
    dataIndex: "service_type",
    key: "service_type",
    width: 80,
    render: (val: string) => <span>{ServiceTypeMap[val]}</span>,
  },
  {
    title: "NAMA LAYANAN",
    dataIndex: "name",
    key: "name",
    width: 120,
    render: (val: string) => <span>{val}</span>,
  },
  {
    title: "KETERANGAN",
    dataIndex: "description",
    key: "description",
    width: 160,
    render: (val: any) => <span>{val}</span>,
  },
  {
    title: "HARGA DASAR",
    dataIndex: "base_price",
    key: "base_price",
    width: 80,
    render: (val: number) => <span>{formatRupiah(val)}</span>,
  },
  {
    title: "IMAGE",
    dataIndex: "image",
    key: "image",
    width: 50,
    render: (val: any) => <img src={val} />,
  }
];

export const ServiceListPage: React.FC<any> = (props) => {

  const [listService, setServiceList] = useState<Array<IServiceModel>>([]);
  const [filter, setFilter] = React.useState<IFilter>({ service_group_code: "", service_type: "", keywords: "" });
  const [pagination, setPagination] = React.useState<IPagination>({});

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiErrorAlert = useApiErrorAlert();

  const { authUser } = useSelector(({ auth }: { auth: any }) => auth);

  const onChangeFilter = React.useCallback((key: string, value: string) => {
    const newFilter = { ...filter, [key]: value };
    loadServiceList(getQueryString(undefined, newFilter));
  }, [filter, pagination]);

  const getQueryParams = React.useCallback((query?: string) => {
    const queryParams = new URLSearchParams(query || location.search);
    let params: any = {};
    queryParams.forEach((v, k) => params[k] = v);
    return params;
  }, [location]);

  const getQueryString = React.useCallback((newPagination?: IPagination, newFilter?: any) => {
    return ("?" + new URLSearchParams({
      ...(newPagination || pagination),
      ...(newFilter || filter)
    }).toString());
  }, [filter, pagination]);

  const loadServiceList = React.useCallback(async (query: string) => {
    const res = await ApiGet(`/service/list${query || ""}`);
    if (res.data && Array.isArray(res.data.data)) {
      setServiceList([...res.data.data]);
      const resPagination = res.data.pagination;
      const newQuery = getQueryParams(query);
      const newFilter = { ...filter, service_group_code: newQuery.service_group_code || "", service_type: newQuery.service_type || "", keywords: newQuery.keywords || "" };
      const newPagination = { perPage: resPagination.perPage, currentPage: resPagination.currentPage, total: resPagination.total };
      const url = getQueryString(newPagination, newFilter);
      setPagination(newPagination);
      setFilter(newFilter);
      history.replace(`/service${url}`, null);
    } else if (!res.success) {
      apiErrorAlert(res);
    }
  }, [listService, filter, pagination]);

  React.useEffect(() => {
    loadServiceList(location.search);
    dispatch(setCurrentRoutes([
      {
        breadcrumbName: "Master",
      },
      {
        path: "/service",
        breadcrumbName: "Daftar Layanan",
      }
    ]));
  }, [location.search]);

  return (
    <Row style={{ rowGap: 0 }}>
      <Col className="ant-col-24">
        <Card title={
          <Row>
            <Col className="ant-col-18">
              <div style={{ display: "flex", flexDirection: "row", paddingTop: 12, paddingLeft: 6 }}>
                <div style={{ display: "flex", flexDirection: "column", width: 200, marginRight: 24 }}>
                  <label style={{ height: 32 }}>Grup Layanan</label>
                  <ServiceGroupSelect addAllOption={true} value={filter.service_group_code} onChange={(val) => onChangeFilter("service_group_code", val)} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: 200, marginRight: 24 }}>
                  <label style={{ height: 32 }}>Tipe Layanan</label>
                  <ServiceTypeSelect addAllOption={true} value={filter.service_type} onChange={(val) => onChangeFilter("service_type", val)} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: 300 }}>
                  <label style={{ height: 32 }}>Pencarian</label>
                  <SearchBox
                    placeholder="Kata Kunci..."
                    onChange={(e: any) => onChangeFilter("keywords", e.target.value)}
                    value={filter.keywords} />
                </div>
              </div>
            </Col>
            {
              authUser?.type === "root" &&
              <Col className="ant-col-6 text-right">
                <Button type="primary" onClick={() => navigate(`/service/new`)}>Tambah Layanan</Button>
              </Col>
            }
          </Row>
        }>
          <Table
            className="gx-table-responsive"
            columns={columns}
            dataSource={listService}
            pagination={{
              pageSize: Number(pagination.perPage),
              total: Number(pagination.total),
              defaultCurrent: 0,
              defaultPageSize: 10,
              current: Number(pagination.currentPage),
              showSizeChanger: true
            }}
            tableLayout="auto"
            scroll={{ y: 425, x: "max-content" }}
            onChange={(valPagination: any, filter) => {
              const newPagination = {
                perPage: Number(valPagination.pageSize),
                currentPage: Number(valPagination.current)
              };
              navigate(`/service${getQueryString(newPagination)}`);
            }}
            onRow={(record, index) => {
              return {
                onClick: () => {
                  navigate(`/service/${record.service_id}`, { state: { from: location.pathname + location.search } });
                }
              }
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};