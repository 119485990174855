import React from "react";
import { GeneralTableView } from "./GeneralTableView";
import { titleCase } from "src/util/Formater";
import { DataMerekView } from "./DataMerekView";
import { GeneralFormView } from "./GeneralFormView";
import { IOrderModel } from "src/type-interface/model";

export type TypeKodeGrupLayanan = "pendirian-badan" | "pendaftaran-merek" | "perubahan-akta" | "kontrak";

export interface IOrderDetail {
    id: number;
    nama_layanan: string;
    price: number;
}

export interface IDataKontrak {
    orderDetail: Array<IOrderDetail>;
    listFileKontrak: Array<{
        filePath: string;
        fileName: string;
        jenisLayanan: "draft" | "review";
    }>;
}

export interface IDataPendaftaranMerek {
    namaMerek: string;
    kelasMerek: { class: string; description: string };
    logoMerekFilePath: string;
    jenisPemilik: "individu" | "badan";
    urlLogoMerek: string;
    pemilikMerek: Array<{
        filePath: string;
        file_name: string;
        jenisPemilik: "individu" | "badan";
        linkDownload: string;
    }>
    orderDetail: Array<IOrderDetail>;
}

export interface IUserPemegangSaham {
    customerId: number;
    idOrder: number;
    jabatan: string;
    ktpName: string;
    npwpName: string;
    ktp_file?: File;
    npwp_file?: File;
    fileLocationKTP?: string;
    fileLocationNPWP?: string;
    linkDownloadKtp?: string;
    linkDownloadNpwp?: string;
}

export interface INamaBadanUsaha {
    namaBadanUsaha1: string;
    namaBadanUsaha2: string;
    namaBadanUsaha3: string;
}

export interface IDataMerek {
    namaMerek: string;
    downloadLogo: string;
    logoMerekFilePath: string;
    kelasMerek: Array<{ kelas: string, kodeKelas: string }>;
}

export interface IDataPendirianBadanMerek {
    namaBadanUsaha: Array<INamaBadanUsaha>;
    dataPemegangSaham: Array<IUserPemegangSaham>;
    orderDetail: Array<IOrderDetail>;
}

export type IJsonData = IDataPendirianBadanMerek & IDataKontrak & IDataPendaftaranMerek;

export interface JsonDataViewerProps {
    order: IOrderModel,
    data: IJsonData;
    // service_group_code: TypeKodeGrupLayanan;
    onChangeValue: (grupData: string, key: string, value: any, index?: number) => void
}

export const dataTitleMapping: { [key: string]: string } = {
    dataPemegangSaham: "Pemegang Saham",
    // namaBadanUsaha: "Nama Badan Usaha",
    // orderDetail: "Order Detail"
}

export const hideColumn: { [key: string]: Array<string> } = {
    dataPemegangSaham: ["customerId", "fileLocationKTP", "fileLocationNPWP"],
    orderDetail: ["service_id", "image", "service_type"],
    pemilikMerek: ["filePath"],
    dataMerek: ["logoMerekFilePath"],
    fileLampiran: ["filePath"],
    listFileKontrak: ["filePath"],
    agendaPerubahan: ["selected", "marginType", "marginValue"]
}

export const DataViewMapping: any = {
    dataMerek: (props: any) => <DataMerekView {...props} />,
    // fileLampiran: (props: any) => <Form {...props} />,
    // orderDetail: (props: any) => <OrderDetailView {...props} />,
    // namaBadanUsaha: (props: any) => <NamaBadanUsahaView {...props} />,
    // dataPemegangSaham: (props: any) => <GeneralTableView {...props} />
}

export const JsonDataViewer: React.FC<JsonDataViewerProps> = (props) => {

    const { order, onChangeValue } = props;

    const convertJsonToView = (json: any): any => {
        if (Array.isArray(json)) {
            return json.map((o: any) => convertJsonToView(o));
        } else {
            return Object.keys(json).map((key: string) => {
                if (typeof json[key] === "object") {
                    if (Array.isArray(json[key])) {
                        return DataViewMapping[key] ?
                            DataViewMapping[key]({
                                data: json[key],
                                onChangeValue: onChangeValue
                            }) :
                            <GeneralTableView
                                key={`GeneralTableView-${key}`}
                                order={order}
                                data={json[key]}
                                onChangeValue={onChangeValue}
                                title={dataTitleMapping[key] || titleCase(key)}
                                hideColumns={hideColumn[key] || []} />
                    } else if (DataViewMapping[key]){
                        return DataViewMapping[key]({
                            data: json[key],
                            onChangeValue: onChangeValue 
                        });
                    } else {
                        return (
                            <GeneralFormView
                                key={`GeneralFormView-${key}`}
                                title={titleCase(key)}
                                order={order}
                                data={json[key]}
                                onChangeValue={onChangeValue}
                                hideColumns={hideColumn[key] || []}
                            />
                        );
                        // return <Card title={<span style={{ paddingLeft: 16 }}>{titleCase(key)}</span>}><pre>{JSON.stringify(json[key], null, 2)}</pre></Card>;
                    }
                }
                return null;
            })
        }
    }

    return (
        <>
            {convertJsonToView(props.data)}
        </>
    );
}