import { Select, SelectProps } from "antd";
import { useEffect, useState } from "react";

export type SelectOptionProps = SelectProps & { options: Array<{ label: string; value: string; }>; addAllOption?: boolean };

const SelectOption: React.FC<SelectOptionProps> = (props) => {

    props = {
        ...props,
        options: props.addAllOption ? [{ label: "Semua", value: "" }].concat(props.options) : props.options
    };

    const [value, setValue] = useState("");

    useEffect(() => {
        if (props.options.length > 0 && !props.value) {
            setValue(props.options[0].value);
        } else {
            setValue(props.value);
        }
    }, [props]);

    return (
        <Select
            className="gx-mb-2"
            size="middle"
            dropdownMatchSelectWidth={true}
            {...props}
            defaultValue={value}
            value={value}>
            {
                props.options.map((o) => (<Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>))
            }
        </Select>
    );
}

export default SelectOption;