import { LOAD_MASTER_DATA, LOAD_MASTER_DATA_SUCCESS, RESET_MASTER_DATA } from "src/constants/ActionTypes";

export const loadMasterDataSuccess = (masterData?: any) => {
    return {
        type: LOAD_MASTER_DATA_SUCCESS,
        payload: masterData
    };
};

export const resetMasterData = () => {
    return {
        type: RESET_MASTER_DATA
    };
};

export const loadMasterData = () => {
    return {
        type: LOAD_MASTER_DATA
    };
};