import React from "react";
import { Breadcrumb } from "antd";
import { Header } from "antd/lib/layout/layout";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const HeaderBreadCrumb: React.FC = () => {

    const { currentRoutes } = useSelector(({ settings }: { settings: any }) => settings);
    const title = (currentRoutes && currentRoutes.length > 0 && currentRoutes[currentRoutes.length-1].breadcrumbName) || "";

    return (
        <Header>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <strong style={{ paddingTop: 8, paddingBottom: 8, fontWeight: 500, textTransform: "uppercase" }}>{title}</strong>
                <Breadcrumb separator=">" itemRender={(route, params, routes, paths) => {
                    const last = routes.indexOf(route) === routes.length - 1;
                    return last ? (
                        <span>{route.breadcrumbName}</span>
                    ) : (
                        <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
                    );
                }} routes={currentRoutes}>
                </Breadcrumb>
            </div>
        </Header>
    );
}

export default HeaderBreadCrumb;