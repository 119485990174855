import React from "react";
import { IDiscountModel } from "src/type-interface/model";
import { ApiGet } from "src/util/ApiClient";
import { formatRupiah } from "src/util/Formater";

export interface ILabelValue {
    label: string;
    value: string;
}

export interface IUseDiscount {
    listDiscount: Array<IDiscountModel>;
    loadDiscount: (partner_code: string) => void;
    findDiscount: (id: number) => IDiscountModel;
    getSelectDiscount: () => Array<ILabelValue>;
    calculateDiscount: (amount: number, id: number) => { discount: number, total: number };
}

export const useDiscount = (): IUseDiscount => {

    const [listDiscount, setListDiscount] = React.useState<Array<any>>([]);
    const loadDiscount = React.useCallback(async (partner_code: string) => {
        const res = await ApiGet(`/discount/list-all?partner_code=${partner_code}`);
        if (res.success) {
            setListDiscount(res.data.data);
        }
    }, [listDiscount]);

    const findDiscount = React.useCallback((id: number) => {
        // let discountName = "";
        // if (listDiscount && listDiscount.length > 0) {
        //     const discount = listDiscount.find((o) => Number(o.discount_id) === id);
        //     if (discount) {
        //         discountName = discount.discount_name;
        //     }
        // }
        return listDiscount?.find((o) => Number(o.discount_id) === id);
    }, [listDiscount]);

    const calculateDiscount = React.useCallback((amount: number, id: number) => {
        const disc = findDiscount(id);
        const discount = disc.discount_type === "percentage" ? amount * (disc.discount_value / 100) : disc.discount_value;
        return {
            discount,
            total: amount - discount
        };
    }, [listDiscount]);

    const getSelectDiscount = React.useCallback(() => {
        let selectList: Array<ILabelValue> = [];
        if (listDiscount && listDiscount.length > 0) {
            selectList = listDiscount.map((o) => ({
                label: `${o.discount_name} - ${o.discount_type === "percentage" ? `${o.discount_value}%` : formatRupiah(o.discount_value)}`,
                value: String(o.discount_id)
            }));
        }
        return selectList;
    }, [listDiscount]);

    return {
        listDiscount,
        loadDiscount,
        findDiscount,
        getSelectDiscount,
        calculateDiscount
    };

}