import { useDispatch } from "react-redux";
import { hideAuthMessage, userSignOut } from "src/appRedux/actions";
import { IApiResponse } from "src/util/ApiClient";
import Swal from "sweetalert2";

export const useApiErrorAlert = () => {
    const dispatch = useDispatch();
    return (res: IApiResponse, onConfirm?: () => void) => Swal.fire({
        title: res.success ? "Berhasil" : "Gagal!",
        text: res.message || res.data.message || "",
        icon: res.success ? "success" : "error",
        confirmButtonText: "Ok"
    }).then((result) => {
        dispatch(hideAuthMessage());
        if (res.errorCode === 101 && result.isConfirmed) {
            dispatch(userSignOut());
        }
        if (onConfirm) {
            onConfirm();
        }
    });
}