import {
    LOAD_MENU, LOAD_MENU_SUCCESS
} from "../../constants/ActionTypes";

export const loadMenu = () => {
    // console.log("XXXXXXXXXX ==> loadMenu");
    return {
        type: LOAD_MENU
    };
};

export const loadMenuSuccess = (menuTree: Array<any>) => {
    // console.log("XXXXXXXXXX ==> loadMenuSuccess");
    return {
        type: LOAD_MENU_SUCCESS,
        payload: menuTree
    }
};