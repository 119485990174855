import React, { useState } from "react";
import SearchBox from "src/components/SearchBox";
import { Table, Card, Row, Col, Button } from "antd";
import { ApiGet } from "src/util/ApiClient";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setCurrentRoutes } from "src/appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import SelectOption from "src/components/SelectOption";
import { useApiErrorAlert } from "src/hooks/user";
import { ICustomerModel, IPagination } from "src/type-interface/model";
import { history } from "src/appRedux/store";

interface IFilter {
  keywords: string;
  partner_code: string;
}

const PartnerName: React.FC<{ partner_code: string }> = ({ partner_code }) => {
  const { listPartner } = useSelector(({ masterData }: { masterData: any }) => masterData);
  return <span>{(listPartner || []).find((o: any) => o.partner_code === partner_code)?.name}</span>
}

const columns = [
  {
    title: "NAMA USER",
    dataIndex: "full_name",
    key: "full_name",
    width: 175,
    render: (val: string, row: ICustomerModel) => <Link to={`/customer/${row.customer_id}`}>{val}</Link>,
  },
  {
    title: "PHONE",
    dataIndex: "phone",
    key: "phone",
    width: 85,
    render: (val: string) => <span>{val}</span>,
  },
  {
    title: "EMAIL",
    dataIndex: "email",
    key: "email",
    width: 100,
    render: (val: number) => <span>{val}</span>,
  },
  {
    title: "PARTNER",
    dataIndex: "partner_code",
    key: "partner_code",
    width: 150,
    render: (val: string) => <PartnerName partner_code={val} />,
  },
  // {
  //   title: "STATUS",
  //   dataIndex: "status",
  //   key: "status",
  //   width: 100,
  //   render: (val: number) => <span>{val}</span>,
  // },
  // {
  //   title: "LAST LOGIN",
  //   dataIndex: "last_login",
  //   key: "last_login",
  //   width: 180,
  //   render: (val: any) => <span>{formatDateTime(val)}</span>,
  // }
];

export const CustomerListPage: React.FC<any> = (props) => {

  const [orderList, setOrderList] = useState<Array<ICustomerModel>>([]);
  const [filter, setFilter] = React.useState<IFilter>({ partner_code: "", keywords: "" });
  const [pagination, setPagination] = React.useState<IPagination>({});

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiErrorAlert = useApiErrorAlert();
  const onChangeFilter = React.useCallback((key: string, value: string) => {
    const newFilter = { ...filter, [key]: value };
    loadOrderList(getQueryString(undefined, newFilter));
  }, [filter, pagination]);

  const getQueryParams = React.useCallback((query?: string) => {
    const queryParams = new URLSearchParams(query || location.search);
    let params: any = {};
    queryParams.forEach((v, k) => params[k] = v);
    return params;
  }, [location]);

  const getQueryString = React.useCallback((newPagination?: IPagination, newFilter?: any) => {
    return ("?" + new URLSearchParams({
      ...(newPagination || pagination),
      ...(newFilter || filter)
    }).toString());
  }, [filter, pagination]);

  const loadOrderList = React.useCallback(async (query: string) => {
    const res = await ApiGet(`/customer/list${query || ""}`);
    if (res.data && Array.isArray(res.data.data)) {
      setOrderList([...res.data.data]);
      const resPagination = res.data.pagination;
      const newQuery = getQueryParams(query);
      const newFilter = { ...filter, partner_code: newQuery.partner_code || "", keywords: newQuery.keywords || "" };
      const newPagination = { perPage: resPagination.perPage, currentPage: resPagination.currentPage, total: resPagination.total };
      const url = getQueryString(newPagination, newFilter);
      setPagination(newPagination);
      setFilter(newFilter);
      history.replace(`/customer${url}`, null);
    } else if (!res.success) {
      apiErrorAlert(res);
    }
  }, [orderList, filter, pagination]);

  const { listPartner } = useSelector(({ masterData }: { masterData: any }) => masterData);

  React.useEffect(() => {
    loadOrderList(location.search);
    dispatch(setCurrentRoutes([
      {
        breadcrumbName: "Master",
      },
      {
        path: "/customer",
        breadcrumbName: "Daftar Pelanggan",
      }
    ]));
  }, [location.search]);

  return (
    <Row style={{ rowGap: 0 }}>
      <Col className="ant-col-24">
        <Card title={
          <div style={{ display: "flex", flexDirection: "row", paddingTop: 12 }}>
            <div style={{ display: "flex", flexDirection: "column", width: 200, marginRight: 24 }}>
              <label style={{ height: 32 }}>Mitra</label>
              <SelectOption
                value={filter.partner_code}
                onChange={(val) => onChangeFilter("partner_code", val)}
                options={listPartner.map((o: any) => ({ label: o.name, value: o.partner_code }))}
                addAllOption={true} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", width: 300 }}>
              <label style={{ height: 32 }}>Pencarian</label>
              <SearchBox
                placeholder="Kata Kunci..."
                onChange={(e: any) => onChangeFilter("keywords", e.target.value)}
                value={filter.keywords} />
            </div>
          </div>
        }>
          <Table
            className="gx-table-responsive"
            columns={columns}
            dataSource={orderList}
            pagination={{
              pageSize: Number(pagination.perPage),
              total: Number(pagination.total),
              defaultCurrent: 0,
              defaultPageSize: 10,
              current: Number(pagination.currentPage),
              showSizeChanger: true
            }}
            tableLayout="auto"
            scroll={{ y: 425, x: "max-content" }}
            // rowClassName={(row) => `row-order-status-${row.status}`}
            onChange={(valPagination: any, filter) => {
              const newPagination = {
                perPage: Number(valPagination.pageSize),
                currentPage: Number(valPagination.current)
              };
              navigate(`/customer${getQueryString(newPagination)}`);
            }}
            // onRow={(record, index) => {
            //   return { onClick: () => navigate(`/customer/${record.customer_id}`, { state: { from: location.pathname + location.search } });
            // }}
          />
        </Card>
      </Col>
    </Row>
  );
};