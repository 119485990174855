import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import configureStore from "./appRedux/store";
import App from "./containers/App/index";
import "./assets/vendors/style";
import "./styles/wieldy.less";
import "./sass/style.scss";
import "sweetalert2/src/sweetalert2.scss";
// import "./firebase/firebase";

const store = configureStore(/* provide initial state if any */);

const NextApp: React.FC = () => {

    return (
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    {/* <Routes>
                    <Route path="/login" element={<SignIn />} />
                    <Route path="/sign-up" element={<SignUp />} />
                    <Route path="/apps/*" element={<ContainerApp />} />
                </Routes> */}
                    <App />
                </BrowserRouter>
            </Provider>
        </React.StrictMode>
    );
}


export default NextApp;
