import React from "react";
import { Card, Form, Input, Select, Table } from "antd";
import { Link } from "react-router-dom";
import { formatRupiah, getFileNameFromUrl, randomString, titleCase } from "src/util/Formater";
import { IOrderModel } from "src/type-interface/model";

const CellMapping: any = {
    jabatan: (val: any, row: any, idx: number, order: IOrderModel): any => {
        let options: Array<any> = [];
        if (order.description === "PT (Perseroan Terbatas)") {
            options = [
                { label: "Bukan Pengurus", value: "Bukan Pengurus" },
                { label: "Direktur Utama", value: "Direktur Utama" },
                { label: "Direktur", value: "Direktur" },
                { label: "Komisaris", value: "Komisaris" },
            ];
        } else if (order.description === "CV (Commanditaire Vennootschap)") {
            options = [
                { label: "Sekutu Aktif", value: "Sekutu Aktif" },
                { label: "Sekutu Pasif", value: "Sekutu Pasif" }
            ]
        } else if (order.description === "PT Perseorangan") {
            options = [
                { label: "1", value: "1" },
                { label: "2", value: "2" }
            ]
        }
        return (
            <Select
                value={val}
                dropdownMatchSelectWidth={true}
                style={{ width: "100%" }}
                options={options} />
        );
    }
};

const RenderCell = (key: string, val: any, row: any, idx: number, order: IOrderModel) => {
    if (typeof val === "number" && val > 10) {
        val = formatRupiah(val);
    }

    if (key.includes("download")) {
        // if (!["http://", "https://", "www."].includes(val)) {
        //     val = GetAppConfig().baseApiOrigin + val;
        // }
        return (
            ["http://", "https://", "www."].includes(val) ?
                <a href={val} rel="noopener noreferrer" target="_blank" download={getFileNameFromUrl(val)} className="ant-btn ant-btn-primary">Download</a> :
                <Link to={{ pathname: val }} target="_blank" download={getFileNameFromUrl(val)} className="ant-btn ant-btn-primary">Download</Link>
        );
    } else if (CellMapping[key]) {
        return CellMapping[key](val, row, idx, order);
    } else {
        return (<span>{val}</span>);
    }
}

export interface GeneralFormViewProps {
    order: IOrderModel;
    data: any;
    title: string;
    hideColumns: Array<string>;
    onChangeValue: (grupData: string, key: string, value: any, index?: number) => void;
}

export const GeneralFormView: React.FC<GeneralFormViewProps> = (props) => {

    const { title, hideColumns } = props;

    const renderItem = React.useCallback((data: any): Array<any> => {
        let item: Array<any> = Object.keys(data).filter((key) => !hideColumns.includes(key))
            .map((key: string) => {
                if (typeof data[key] === "object") {
                    return renderForm(data[key], titleCase(key));
                } else {
                    return (
                        <Form.Item key={`${key}`} label={titleCase(key)} htmlFor={`${key}`}>
                            {
                                !key.includes("download") ?
                                    <Input id={`${key}`} key={`${key}`} value={data[key]} disabled={true} /> :
                                    (
                                        ["http://", "https://", "www."].includes(data[key]) ?
                                            <a id={`${key}`} key={`${key}`} href={data[key]} rel="noopener noreferrer" target="_blank" download={getFileNameFromUrl(data[key])} className="ant-btn ant-btn-primary">Download</a> :
                                            <Link id={`${key}`} key={`${key}`} to={{ pathname: data[key] }} target="_blank" download={getFileNameFromUrl(data[key])} className="ant-btn ant-btn-primary">Download</Link>
                                    )
                            }
                        </Form.Item>
                    );
                }
            });
        return item;
    }, []);

    const renderForm = React.useCallback((data: any, title: string) => {
        return (
            <Card key={`card-form-${title}`} id={`card-form-${title}`}  bordered={true} title={<span style={{ paddingLeft: 16 }}>{title}</span>}>
                <Form key={`form-${title}`} id={`form-${title}`} layout="horizontal" labelCol={{ span: 4 }} title={title}>
                    {renderItem(data)}
                </Form>
            </Card>
        );
    }, [props.data]);

    return renderForm(props.data, title);
}