import { SelectProps } from "antd";
import { useSelector } from "react-redux";
import SelectOption, { SelectOptionProps } from "src/components/SelectOption";

type TypeSelectOption = SelectProps & { addAllOption?: boolean };

export const PartnerSelect: React.FC<TypeSelectOption> = (props) => {

    /* Exclude addAllOption */
    const { addAllOption, ...tmpProps } = props as SelectOptionProps;
    const { listPartner } = useSelector(({ masterData }: { masterData: any }) => masterData);

    return (
        <SelectOption
            {...tmpProps}
            options={listPartner.map((o: any) => ({ label: o.name, value: o.partner_code }))}
            addAllOption={props.addAllOption} />
    );
}


export const StatusOrderMap: any = {
    "draft": "Draft",
    "incomplete-data": "Data Belum Lengkap",
    "unpaid": "Belum Lunas",
    "paid": "Lunas",
    "failed": "Gagal",
    "rejected": "Ditolak",
    "fraud": "Palsu"
}

export const StatusOrdeSelect: React.FC<TypeSelectOption> = (props) => {

    
    /* Exclude addAllOption */
    const { addAllOption, ...tmpProps } = props as SelectOptionProps;
    return (
        <SelectOption
            {...tmpProps}
            options={Object.keys(StatusOrderMap).map((o: string) => ({ label: StatusOrderMap[o], value: o }))}
            addAllOption={props.addAllOption} />
    );
}

export const ServiceTypeMap: { [key: string]: string } = {
    main: "Utama",
    additional: "Tambahan",
    other: "Lainnya"
}

export const ServiceTypeSelect: React.FC<TypeSelectOption> = (props) => {

    
    /* Exclude addAllOption */
    const { addAllOption, ...tmpProps } = props as SelectOptionProps;
    return (
        <SelectOption
            {...tmpProps}
            options={Object.keys(ServiceTypeMap).map((key: string) => ({ label: ServiceTypeMap[key], value: key }))}
            addAllOption={props.addAllOption} />
    );
}

export const ServiceGroupSelect: React.FC<TypeSelectOption> = (props) => {

    const { listServiceGroup } = useSelector(({ masterData }: { masterData: any }) => masterData);
    
    /* Exclude addAllOption */
    const { addAllOption, ...tmpProps } = props as SelectOptionProps;
    return (
        <SelectOption
            {...tmpProps}
            options={listServiceGroup.map((o: any) => ({ label: o.service_group_name, value: o.service_group_code }))}
            addAllOption={props.addAllOption} />
    );
}
