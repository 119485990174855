import moment from "moment";
import jsConverCase from "js-convert-case"

export const formatRupiah = (money: string | number): string => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(Number(money || 0));
}

export const formatDateTime = (dateString: string, formatDate: string = "DD-MM-YYYY HH:mm:ss") => {
    if (!dateString) { return "" };
    return moment(new Date(dateString)).format(formatDate);
}

export const formatDate = (dateString: string, formatDate: string = "DD-MM-YYYY") => {
    if (!dateString) { return "" };
    return moment(new Date(dateString)).format(formatDate);
}

export const titleCase = (text: string) => {
    // const result = text.replace(/([A-Z])/g, " $1");
    // const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    // return finalResult;
    return jsConverCase.toHeaderCase(text);   
}

export const randomString = () => {
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    var string_length = 8;
    var randomstring = "";
    for (var i = 0; i < string_length; i++) {
        var rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
}

export const getFileNameFromUrl = (url: string) => {
    return url.substring(url.lastIndexOf("/")+1);
}