import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "../../lngProvider";
import { loadProfile, setInitUrl } from "../../appRedux/actions";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  // THEME_TYPE_DARK
} from "../../constants/ThemeSetting";

import MainApp from "./MainApp";
// import DashboardPage from "src/routes/DashboardPage";
import OrderListPage from "src/routes/OrderListPage";
import OrderDetailPage from "src/routes/OrderDetailPage";
import { SignIn, SignUp } from "../../routes/Auth";
import { loadMasterData } from "src/appRedux/actions/MasterData";
import { getCookie } from "src/util/ApiClient";
import { UserListPage } from "src/routes/user";
import { ServiceListPage } from "src/routes/service/ServiceList";
import { PartnerListPage } from "src/routes/partner";
import { CustomerListPage } from "src/routes/customer";
import ServiceDetailPage from "src/routes/service/ServiceDetail";
import PartnerDetailPage from "src/routes/partner/PartnerDetail";
import { DiscountListPage } from "src/routes/discount/DiscountList";

// import { ContainerMfePage } from "../../routes/ContainerMicroFrontend";

const setLayoutType = (layoutType: string) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("full-layout");
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove("full-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("boxed-layout");
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("full-layout");
    document.body.classList.add("framed-layout");
  }
};

const setNavStyle = (navStyle: string) => {
  if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER) {
    document.body.classList.add("full-scroll");
    document.body.classList.add("horizontal-layout");
  } else {
    document.body.classList.remove("full-scroll");
    document.body.classList.remove("horizontal-layout");
  }
};

const ProtectedRoute: React.FC<any> = ({ children, authUser }: any) => {
  if (!authUser) {
    return <Navigate to="/login" />;
  }
  return children;
};

// const RoutesControll: React.FC = () => {
//   const { authUser } = useSelector(({ auth }: { auth: any }) => auth);
//   const location = useLocation();
//   const navigate = useNavigate();

//   React.useEffect(() => {
//     if (authUser  && location.pathname === "/login") {
//       console.log("GOTO DASHBOARD");
//       navigate("/dashboard");
//     }
//   }, [authUser]);

//   return !authUser && location.pathname !== "/login" ? <Navigate to="/login" replace /> : null;
// }

const urlNoAuth = (location: any): boolean => {
  const URL_NO_AUTH: Array<string> = [
    "/login", "/sign-up", "/dashboard", "/test1"
  ];
  const findIndex: number = URL_NO_AUTH.findIndex((path) => path === location.pathname);
  return findIndex >= 0;
}

const App: React.FC = () => {
  const locale = useSelector(({ settings }: { settings: any }) => settings.locale);
  const navStyle = useSelector(({ settings }: { settings: any }) => settings.navStyle);
  const layoutType = useSelector(({ settings }: { settings: any }) => settings.layoutType);
  // const themeType = useSelector(({ settings }: { settings: any }) => settings.themeType);
  const isDirectionRTL = useSelector(({ settings }: { settings: any }) => settings.isDirectionRTL);
  const { loaded } = useSelector(({ masterData }: { masterData: any }) => masterData);

  const { authUser /*, initURL*/ } = useSelector(({ auth }: { auth: any }) => auth);
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add("rtl");
      document.documentElement.setAttribute("data-direction", "rtl")
    } else {
      document.documentElement.classList.remove("rtl");
      document.documentElement.setAttribute("data-direction", "ltr")
    }
  }, [isDirectionRTL]);

  React.useEffect(() => {
    if (locale)
      document.documentElement.lang = locale.locale;
  }, [locale]);

  // React.useEffect(() => {
  //   if (themeType === THEME_TYPE_DARK) {
  //     document.body.classList.add("dark-theme");
  //   } else if (document.body.classList.contains("dark-theme")) {
  //     document.body.classList.remove("dark-theme");
  //   }
  // }, [themeType]);

  React.useEffect(() => {
    if (getCookie()) {
      dispatch(loadProfile());
    }
  }, []);

  React.useEffect(() => {
    if (!authUser && location.pathname !== "/login" && urlNoAuth(location)) {
      navigate("/login");
    }
    
    if (!urlNoAuth(location) && getCookie()) {
      if (!loaded) {
        dispatch(loadMasterData());
      }
    }

    if (authUser && ["/", "/login", "/sign-up"].includes(location.pathname)) {
      dispatch(setInitUrl(location.pathname));
      navigate("/order");
    }

  }, [authUser, location]);
  // , initURL, history]);

  React.useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd} direction={isDirectionRTL ? "rtl" : "ltr"}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <Routes>
          <Route path="/" element={
            <ProtectedRoute authUser={authUser}>
              <MainApp />
            </ProtectedRoute>
          }>
            <Route path="/user" element={<UserListPage />} />
            <Route path="/order" element={<OrderListPage />} />
            <Route path="/order/new" element={<OrderDetailPage />} />
            <Route path="/order/:id" element={<OrderDetailPage />} />
            <Route path="/service" element={<ServiceListPage />} />
            <Route path="/partner" element={<PartnerListPage />} />
            <Route path="/partner/new" element={<PartnerDetailPage />} />
            <Route path="/partner/:id" element={<PartnerDetailPage />} />
            <Route path="/customer" element={<CustomerListPage />} />
            <Route path="/service/new" element={<ServiceDetailPage />} />
            <Route path="/service/:id" element={<ServiceDetailPage />} />
            <Route path="/discount" element={<DiscountListPage />} />
          </Route>
          <Route path="/login" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
        </Routes>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default React.memo(App);
// export default App;
