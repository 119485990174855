import Swal, { SweetAlertIcon } from "sweetalert2";

export const showConfirm = (options: { title: string, message: string, icon: SweetAlertIcon, confirmButtonText?: string; }, onConfirm?: () => void) => Swal.fire({
    title: options.title,
    text: options.message,
    icon: options.icon,
    confirmButtonText: options.confirmButtonText || "OK"
}).then((result) => {
    if (result.isConfirmed && onConfirm) {
        onConfirm();
    }
});