import React, { useEffect } from "react";
import { Form, Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  hideAuthMessage,
  showAuthLoader,
  userSignIn
} from "../../appRedux/actions";
import IntlMessages from "../../util/IntlMessages";
import CircularProgress from "../../components/CircularProgress";
import { useApiErrorAlert } from "src/hooks/user";
import { CustomizedForm } from "src/components/Form/CustomizedForm";
// import { useDebounce } from "use-lodash-debounce";

// const logo = require("../../assets/images/logo-head.png");
// import * as logo from "../../assets/images/logo-head-kh.png";
const logo = "/assets/images/logo-head.png";

export interface UserLogin {
  email: string;
  password: string;
  remember: boolean;
}

export const SignIn: React.FC = () => {

  const [form] = Form.useForm<UserLogin>();
  const { loader, showMessage, authUser, alertMessage } = useSelector(({ auth }: { auth: any }) => auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiErrorAlert = useApiErrorAlert();

  useEffect(() => {
    // if (showMessage) {
    //   setTimeout(() => {
    //     dispatch(hideMessage());
    //   }, 100);
    // }
    if (authUser !== null) {
      navigate("/");
    }
  }, []);

  React.useEffect(() => {
    if (showMessage && alertMessage) {
      apiErrorAlert({ success: false, message: alertMessage }, () => dispatch(hideAuthMessage()));
    }
  }, [showMessage]);

  // React.useEffect(() => {
  //   if (authUser  && location.pathname === "/login") {
  //     console.log("GOTO DASHBOARD");
  //     navigate("/dashboard");
  //   }
  // }, [authUser]);

  const onFinishFailed = (errorInfo: any) => {
    // console.log("tsx-XXX--errorInfo", errorInfo);
  };

  const onFinish = async (values: UserLogin) => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  return (
    <div className="gx-app-login-wrap" style={{ backgroundImage: `url("/assets/images/bg-pattern.svg")` }}>
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              {/* <img src="/assets/images/harmony.svg" style={{ padding: 70 }} alt="Neature" /> */}
            </div>
            <div className="gx-app-logo-wid">
              <div style={{ width: "100%", textAlign: "center", marginBottom: 50 }}>
                <img src={logo} style={{ height: 50 }} alt="example" />
              </div>
              <h1><IntlMessages id="app.userAuth.signIn" /></h1>
              <p><IntlMessages id="app.userAuth.bySigning" /></p>
              <p><IntlMessages id="app.userAuth.getAccount" /></p>
            </div>
            {/* <div className="gx-app-logo">
              <img src="/src/assets/images/logo-head-kh.png" style={{ width: 50 }} alt="example" />
            </div> */}
          </div>
          <div className="gx-app-login-content">
            <CustomizedForm
              debounce={500}
              onChangedField={(key: any, val: any) => console.log("XXX ---> onChangedField", { key, val })}
              // onValuesChange={(changed, value) => {
              //   console.log("tsx-XXXX", Object.keys(changed));
              //   console.log("tsx-XXXX", { changed, value });
              // }}
              form={form}
              initialValues={{ remember: true, email: "", password: "" }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              <Form.Item
                // initialValue="rovi.riyadi@harmony.co.id"
                rules={[
                  { required: true, message: "Please input your E-mail!" },
                  { type: "email", message: "The input is not valid E-mail!" }
                ]} name="email">
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                // initialValue="demo#123"
                rules={[
                  { required: true, message: "Please input your Password!" },
                  { min: 6, message: "Minimal 6 karakter!" }
                ]} name="password">
                <Input type="password" placeholder="Password" />
                {/* <MonyInputX type="password" className="ant-input" />
                <MonyInput className="ant-input" /> */}
              </Form.Item>

              {/* <Form.Item>
                <Checkbox><IntlMessages id="appModule.iAccept" /></Checkbox>
                <span className="gx-signup-form-forgot gx-link"><IntlMessages
                  id="appModule.termAndCondition" /></span>
              </Form.Item> */}
              {/* <Form.Item>
                <Button type="primary" className="gx-mb-0" onClick={() => {
                  console.log("tsx-email", form.getFieldValue("email"));
                  console.log("tsx-password", form.getFieldValue("password"));
                  console.log("tsx-data-all", form.getFieldsValue());
                  console.log("tsx-error-email", form.getFieldError("email"));
                  console.log("tsx-error-password", form.getFieldError("password"));
                  console.log("tsx-error-all", form.getFieldsError());
                  form.resetFields(["password"]);
                  form.validateFields();
                }}>
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </Form.Item> */}
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </Form.Item>
              <Form.Item>
                <Link to="/forgot"><IntlMessages id="app.userAuth.forgotPassword" /></Link>
              </Form.Item>

              {/* <div className="gx-flex-row gx-justify-content-between">
                <span>or connect with</span>
                <ul className="gx-social-link">
                  <li>
                    <GoogleOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userGoogleSignIn());
                    }} />
                  </li>
                  <li>
                    <FacebookOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userFacebookSignIn());
                    }} />
                  </li>
                  <li>
                    <GithubOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userGithubSignIn());
                    }} />
                  </li>
                  <li>
                    <TwitterOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userTwitterSignIn());
                    }} />
                  </li>
                </ul>
              </div> */}

              <div className="gx-flex-row">
                {/* <span>New to Harmony? <Link to="/sign-up"> Sign Up Free</Link></span> */}
              </div>
              {/* <span className="gx-text-light gx-fs-sm"> demo user email: "rovi.riyadi@harmony.co.id" and password: "demo#123"</span> */}

            </CustomizedForm>
          </div>

          <span style={{ width: "100%", textAlign: "center", padding: 20 }}>
            {/* <img src="/assets/images/harmony.svg" style={{ width: 30 }} alt="example" /> */}
            Copyright © 2023 by <a href="https://kontrakhukum.com">Kontrak Hukum</a>
          </span>

          <>{loader ? <div className="gx-loader-view"> <CircularProgress /> </div> : null}</>
          {/* <>{showMessage ? message.error(alertMessage.toString()) : null}</> */}

        </div>
      </div>
    </div>
  );
};