import Axios, { AxiosRequestConfig, Method } from "axios";
import {
    TOKEN_KEY,
    BASE_API_ORIGIN,
    BASE_API_PATH
} from "src/constants/AppConstants";

export const GetAppConfig: () => { baseApiOrigin: string; baseApiPath: string } = () => ({
    baseApiOrigin: BASE_API_ORIGIN,
    baseApiPath: BASE_API_PATH
});

export const setCookie = (token: string, cookieName: string = TOKEN_KEY) => {
    localStorage.setItem(cookieName, token);
}

export const getCookie = (name: string = TOKEN_KEY) => {
    return localStorage[name];
}

export const guid = () => {
    const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" +
        s4() + "-" + s4() + s4() + s4();
}

export interface IErrorModel {
    message: string;
    path?: string;
}

export interface IApiResponse {
    success?: boolean,
    data?: any,
    message: string;
    errorCode?: number;
    // message?: IErrorModel[];
}

const responseDefault: IApiResponse = {
    success: false,
    data: {},
    // message: []
    message: ""
};

const createInstance = (method: Method, config?: AxiosRequestConfig, header: { [key: string]: string } = {}) => {
    let headers = {
        "Content-Type": "application/json",
        [TOKEN_KEY]: getCookie(),
        ...header
    };
    if (config && !config.withCredentials) {
        delete headers[TOKEN_KEY];
    }
    return Axios.create({
        withCredentials: true,
        headers: headers,
        method: method,
        validateStatus: (status: number) => status === status, // Always True
        ...config
    });
}

export const ApiGet = async (apiPath: string, config?: AxiosRequestConfig, header: { [key: string]: string } = {}) => {
    let response: IApiResponse = responseDefault;
    try {
        let api = await createInstance("GET", config, header).get(GetAppConfig().baseApiPath + apiPath);
        if (api.status.toString().startsWith("2")) {
            response = { ...response, success: true, data: api.data };
        } else if (api.status === 404) {
            throw Error("Not found");
        } else {
            response = { ...response, success: false, message: api.data.message, errorCode: api.data.errorCode };
        }
    } catch (error: any) {
        response = { ...response, success: false, message: "Please check your connection...!" };
    }
    return response;
}

export const ApiPost = async (apiPath: string, data: any, config?: AxiosRequestConfig): Promise<any> => {
    let response: IApiResponse = responseDefault;
    try {
        let api = await createInstance("POST", config).post(GetAppConfig().baseApiPath + apiPath, data);
        if (api.status.toString().startsWith("2")) {
            response = { ...response, success: true, data: api.data };
        } else if (api.status === 404) {
            throw Error("Not found");
        } else {
            response = { ...response, success: false, message: api.data.message, errorCode: api.data.errorCode };
        }
    } catch (error: any) {
        response = { ...response, success: false, message: "Please check your connection...!" };
    }
    return response;
}

export const ApiPut = async (apiPath: string, data: any, config?: AxiosRequestConfig) => {
    let response: IApiResponse = responseDefault;
    try {
        let api = await createInstance("PUT", config).put(GetAppConfig().baseApiPath + apiPath, data);
        if (api.status.toString().startsWith("2")) {
            response = { ...response, success: true, data: api.data };
        } else if (api.status === 404) {
            throw Error("Not found");
        } else {
            response = { ...response, success: false, message: api.data.message, errorCode: api.data.errorCode };
        }
    } catch (error: any) {
        response = { ...response, success: false, message: "Please check your connection...!" };
    }
    return response;
}

export const ApiDelete = async (apiPath: string, config?: AxiosRequestConfig) => {
    let response: IApiResponse = responseDefault;
    try {
        let api = await createInstance("DELETE", config).delete(GetAppConfig().baseApiPath + apiPath);
        if (api.status.toString().startsWith("2")) {
            response = { ...response, success: true, data: api.data };
        } else if (api.status === 404) {
            throw Error("Not found");
        } else {
            response = { ...response, success: false, message: api.data.message, errorCode: api.data.errorCode };
        }
    } catch (error: any) {
        response = { ...response, success: false, message: "Please check your connection...!" };
    }
    return response;
}

// export const ApiDownloadFile = (srcUrl: string, method: Method = "get", data: any = {}) => {
//     const protocol = srcUrl.split('/')[0];
//     const fileUrl = protocol.includes("http") ? srcUrl : GetAppConfig().baseApiPath + srcUrl;
//     return new Promise<IApiResponse>((resolve, _reject) => {
//         if (method.toLowerCase() === "get") {
//             agent
//                 .get(fileUrl)
//                 .withCredentials()
//                 .responseType("blob")
//                 .buffer(true)
//                 // .set(TOKEN_KEY, Common.getCookie())
//                 .use(_superagentNoCache)
//                 // .timeout(60 * 1000 * 2)
//                 .end((error: any, res: Response) => {
//                     if (error && error.response && error.response.status !== 200) {
//                         resolve({ ...responseDefault, success: false, message: error.response.statusText });
//                     } else {
//                         resolve({ ...responseDefault, success: true, data: res.body });
//                     }
//                 });
//         } else if (method.toLowerCase() === "post") {
//             agent
//                 .post(fileUrl)
//                 .send(data)
//                 .withCredentials()
//                 .responseType("blob")
//                 .buffer(true)
//                 .set(TOKEN_KEY, Common.getCookie())
//                 .use(_superagentNoCache)
//                 // .timeout(60 * 1000 * 2)
//                 .end((error, res) => {
//                     if (error && error.response && error.response.status !== 200) {
//                         resolve({ ...responseDefault, success: false, message: error.response.statusText });
//                     } else {
//                         resolve({ ...responseDefault, success: true, data: res.body });
//                     }
//                 });
//         }
//     });
// }

// export const ApiDownloadFileExcel = (srcUrl: string, data: any) => {
//     return new Promise<IApiResponse>((resolve, _reject) => {
//         agent
//             .post(GetAppConfig().baseApiPath + srcUrl, data)
//             .withCredentials()
//             .responseType("blob")
//             .buffer(true)
//             .set(TOKEN_KEY, Common.getCookie())
//             .use(_superagentNoCache)
//             .timeout(60 * 1000 * 2)
//             .end((error, res) => {
//                 if (error && error.response && error.response.status !== 200) {
//                     resolve({ ...responseDefault, success: false, message: error.response.statusText });
//                 } else {
//                     resolve({ ...responseDefault, success: true, data: res.body });
//                 }
//             });
//     });
// }