import React, { useState } from "react";
import SearchBox from "src/components/SearchBox";
import { Table, Card, Row, Col, Button, Form, Input, Divider, Checkbox } from "antd";
import { ApiGet, ApiPost, ApiPut } from "src/util/ApiClient";
import { formatDateTime } from "src/util/Formater";
import { useLocation, useNavigate } from "react-router-dom";
import { setCurrentRoutes } from "src/appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useApiErrorAlert } from "src/hooks/user";
import { defaultUserValue, IPagination, IPartnerModel, IUserModel } from "src/type-interface/model";
import { CustomizedForm } from "src/components/Form/CustomizedForm";
import Modal from "antd/lib/modal/Modal";
import SelectOption from "src/components/SelectOption";
import { history } from "src/appRedux/store";

const typeUsers: Array<{ label: string, value: string }> = [{ label: "Root", value: "root" }, { label: "Admin", value: "admin" }, { label: "Read Only", value: "readonly" }];

export const getTypeName = (type: string): string => {
  return typeUsers.find(o => o.value === type)?.label || "";
}

interface IFilter {
  keywords: string;
  type: string;
}

const columns = [
  {
    title: "NAMA USER",
    dataIndex: "user_name",
    key: "user_name",
    width: 175,
    render: (val: any) => <span>{val}</span>,
  },
  {
    title: "EMAIL",
    dataIndex: "email",
    key: "email",
    width: 100,
    render: (val: number) => <span>{val}</span>,
  },
  {
    title: "PHONE",
    dataIndex: "phone",
    key: "phone",
    width: 85,
    render: (val: string) => <span>{val}</span>,
  },
  {
    title: "TIPE",
    dataIndex: "type",
    key: "type",
    width: 80,
    render: (val: any) => <span>{getTypeName(val)}</span>,
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    width: 100,
    render: (val: number) => <span>{val}</span>,
  },
  {
    title: "LAST LOGIN",
    dataIndex: "last_login",
    key: "last_login",
    width: 180,
    render: (val: any) => <span>{formatDateTime(val)}</span>,
  }
];

export const UserListPage: React.FC<any> = () => {

  const [userList, setUserList] = useState<Array<IUserModel>>([]);
  const [filter, setFilter] = React.useState<IFilter>({ type: "", keywords: "" });
  const [pagination, setPagination] = React.useState<IPagination>({});

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiErrorAlert = useApiErrorAlert();

  const onChangeFilter = React.useCallback((key: string, value: string) => {
    const newFilter = { ...filter, [key]: value };
    loadUserList(getQueryString(undefined, newFilter));
  }, [filter, pagination]);

  const getQueryParams = React.useCallback((query?: string) => {
    const queryParams = new URLSearchParams(query || location.search);
    let params: any = {};
    queryParams.forEach((v, k) => params[k] = v);
    return params;
  }, [location]);

  const getQueryString = React.useCallback((newPagination?: IPagination, newFilter?: any) => {
    return ("?" + new URLSearchParams({
      ...(newPagination || pagination),
      ...(newFilter || filter)
    }).toString());
  }, [filter, pagination]);

  const loadUserList = React.useCallback(async (query: string) => {
    const res = await ApiGet(`/user/list${query || ""}`);
    if (res.data && Array.isArray(res.data.data)) {
      setUserList([...res.data.data]);
      const resPagination = res.data.pagination;
      const newQuery = getQueryParams(query);
      const newFilter = { ...filter, type: newQuery.type || "", keywords: newQuery.keywords || "" };
      const newPagination = { perPage: resPagination.perPage, currentPage: resPagination.currentPage, total: resPagination.total };
      const url = getQueryString(newPagination, newFilter);
      setPagination(newPagination);
      setFilter(newFilter);
      history.replace(`/user${url}`, null);
    } else if (!res.success) {
      apiErrorAlert(res);
    }
  }, [userList, filter, pagination]);

  React.useEffect(() => {
    loadUserList(location.search);
    dispatch(setCurrentRoutes([
      {
        breadcrumbName: "Master",
      },
      {
        path: "/user",
        breadcrumbName: "Admin",
      }
    ]));
  }, [location.search]);

  const { authUser } = useSelector(({ auth }: { auth: any }) => auth);
  const { listPartner } = useSelector(({ masterData }: { masterData: any }) => masterData);

  const [form] = Form.useForm<IUserModel>();
  const [visibleDetail, setVisibleDetail] = React.useState<boolean>(false);

  const onSubmitDetail = React.useCallback(async () => {
    const val = form.getFieldsValue();
    let res;
    if (val.user_id === 0) {
      res = await ApiPost("/user/create", val);
    } else {
      res = await ApiPut(`/user/update/${val.user_id}`, val);
    }
    if (res.data.success) {
      form.resetFields();
      loadUserList(location.search);
    }
    apiErrorAlert(res);
  }, [form, userList]);

  const onShowModalDetail = React.useCallback(async (user_id: number) => {
    const res = await ApiGet(`/user/detail/${user_id}`);
    if (res.data) {
      res.data.data.user_partner = listPartner.map((o: IPartnerModel) => ({
        partner_code: o.partner_code,
        name: o.name,
        checked: res.data.data.user_partner.findIndex((x: any) => x.partner_code === o.partner_code) !== -1
      }));
      form.setFieldsValue(res.data.data);
      setVisibleDetail(true);
    } else {
      apiErrorAlert(res);
    }
  }, [form, visibleDetail, authUser, listPartner]);

  const onNewUser = React.useCallback(async (e: any) => {
    const user_partner = listPartner.map((o: IPartnerModel) => ({
      partner_code: o.partner_code,
      name: o.name,
      checked: false
    }));
    form.setFieldsValue({ ...defaultUserValue, user_partner: user_partner });
    setVisibleDetail(true);
  }, [form, visibleDetail, listPartner]);

  return (
    <>
      <Modal
        title={form.getFieldValue("user_id") === 0 ? "Tambah Pengguna Baru" : "Detail Pengguna"}
        open={visibleDetail}
        width={700}
        footer={
          authUser?.type === "root" &&
          // <div>
          //   <Button type="text" onClick={() => setVisibleDetail(!visibleDetail)}>Batal</Button>
            <Button type="primary" onClick={onSubmitDetail}>Simpan</Button>
          // </div>
        }
        onCancel={() => setVisibleDetail(false)}>
        <CustomizedForm
          debounce={500}
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          name="basic"
          form={form}
          className="gx-signin-form gx-form-row">
          <Row>
            <Col className="ant-col ant-col-12 ant-col-xs-24 ant-col-xl-12">
              <Divider dashed orientation="left">User</Divider>
              <Form.Item name="user_id" hidden />
              <Form.Item
                label="Nama User"
                rules={[
                  { required: true, message: "Input Nama User!" },
                  { min: 6, message: "Minimal 6 karakter!" }
                ]}
                name="user_name">
                <Input placeholder="Nama User" readOnly={authUser && authUser.type !== "root"} />
              </Form.Item>
              <Form.Item
                label="Email"
                rules={[
                  { required: true, message: "Input alamat email!" },
                  { type: "email", message: "Format harus email!" }
                ]}
                name="email">
                <Input type="email" placeholder="Email" readOnly={authUser && authUser.type !== "root"} />
              </Form.Item>
              <Form.Item
                label="No. Telepon"
                rules={[
                  { required: true, message: "Input nomor telepon!" }
                ]}
                name="phone">
                <Input placeholder="No. Telepon" readOnly={authUser && authUser.type !== "root"} />
              </Form.Item>
              <Form.Item
                label="Jenis User"
                rules={[
                  { required: true, message: "Pilih Jenis User!" }
                ]}
                name="type">
                <SelectOption options={typeUsers} disabled={authUser && authUser.type !== "root"} />
              </Form.Item>
              {
                form.getFieldValue("user_id") === 0 &&
                <Form.Item label="Kata Sandi" name="password" rules={[{ required: true, message: "Please input your Password!" }, { min: 6, message: "Minimal 6 karakter!" }]}>
                  <Input placeholder="Kata Sandi" />
                </Form.Item>
              }
              {
                form.getFieldValue("user_id") > 0 &&
                <Form.Item
                  label="Status"
                  rules={[
                    { required: true, message: "Pilih Status!" }
                  ]}
                  name="status">
                  <SelectOption addAllOption={false} options={[{ label: "Aktif", value: "active" }, { label: "Tidak Aktif", value: "inactive" }]} onChange={(val) => onChangeFilter("status", val)} />
                </Form.Item>
              }
            </Col>
            <Col className="ant-col ant-col-12 ant-col-xs-24 ant-col-xl-12">
              {
                form.getFieldValue("type") !== "root" &&
                <>
                  <Divider dashed orientation="left">Tambah Akses ke Data Mitra</Divider>
                  <Form.List name="user_partner">
                    {
                      (fields) => fields.map((field, index) => (
                        <Form.Item
                          key={field.key}
                          name={[index, "checked"]}
                          valuePropName="checked">
                          <Checkbox>{form.getFieldValue(["user_partner", field.name, "name"])}</Checkbox>
                        </Form.Item>
                      ))
                    }
                  </Form.List>
                </>
              }
            </Col>
          </Row>
        </CustomizedForm>
      </Modal>
      <Row style={{ rowGap: 0 }}>
        <Col className="ant-col-24">
          <Card title={
            <Row>
              <Col className="ant-col-18">
                <div style={{ display: "flex", flexDirection: "row", paddingTop: 12, paddingLeft: 6 }}>
                  <div style={{ display: "flex", flexDirection: "column", width: 200, marginRight: 24 }}>
                    <label style={{ height: 32 }}>Tipe</label>
                    <SelectOption
                      value={filter.type}
                      onChange={(val) => onChangeFilter("type", val)}
                      options={typeUsers}
                      addAllOption={true} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", width: 300 }}>
                    <label style={{ height: 32 }}>Pencarian</label>
                    <SearchBox
                      placeholder="Kata Kunci..."
                      onChange={(e: any) => onChangeFilter("keywords", e.target.value)}
                      value={filter.keywords} />
                  </div>
                </div>
              </Col>
              {
                authUser?.type === "root" &&
                <Col className="ant-col-6 text-right">
                  <Button type="primary" onClick={onNewUser}>Tambah Pengguna</Button>
                </Col>
              }
            </Row>
          }>
            <Table
              className="gx-table-responsive"
              columns={columns}
              dataSource={userList}
              pagination={{
                pageSize: Number(pagination.perPage),
                total: Number(pagination.total),
                defaultCurrent: 0,
                defaultPageSize: 10,
                current: Number(pagination.currentPage),
                showSizeChanger: true
              }}
              tableLayout="auto"
              scroll={{ y: 425, x: "max-content" }}
              // rowClassName={(row) => `row-order-status-${row.status}`}
              onChange={(valPagination: any) => {
                const newPagination = {
                  perPage: Number(valPagination.pageSize),
                  currentPage: Number(valPagination.current)
                };
                navigate(`/user${getQueryString(newPagination)}`);
              }}
              onRow={(record) => {
                return { onClick: () => onShowModalDetail(record.user_id) };
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};