import { Card, Col, Form, Image, Input, List, Row } from "antd";
import { Link } from "react-router-dom";
import { getFileNameFromUrl } from "src/util/Formater";
import { IDataMerek } from "./JsonDataView";

export interface DataMerekViewProps {
    data: IDataMerek;
    onChangeValue: (grupData: string, key: string, value: any, index?: number) => void;
}

export const DataMerekView: React.FC<DataMerekViewProps> = (props) => {

    const { data } = props;

    return (
        <Card title={<span style={{ paddingLeft: 16 }}>Data Merek</span>}>
            <Row>
                <Col className="ant-col-8">
                    <Form layout="horizontal">
                        <Form.Item label="Nama Merek" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            {/* <span>{data.namaMerek}</span> */}
                            <Input value={data.namaMerek} readOnly={true} />
                        </Form.Item>
                        <Form.Item label=" " colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <div className="text-center" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Image src={data.downloadLogo} height={100} wrapperStyle={{ marginBottom: 12 }} style={{ width: "auto" }} />
                                {
                                    ["http://", "https://", "www."].includes(data.downloadLogo) ?
                                        <a href={data.downloadLogo} rel="noopener noreferrer" target="_blank" download={getFileNameFromUrl(data.downloadLogo)} className="ant-btn ant-btn-primary" style={{ width: "auto" }}>Download</a> :
                                        <Link to={{ pathname: data.downloadLogo }} target="_blank" download={getFileNameFromUrl(data.downloadLogo)} className="ant-btn ant-btn-primary" style={{ width: "auto" }}>Download</Link>
                                }
                            </div>
                        </Form.Item>
                    </Form>
                </Col>
                <Col className="ant-col-16">
                    <List
                        key="listKelasMerek"
                        id="listKelasMerek"
                        size="small"
                        header={<div>Kelas Merek</div>}
                        // footer={<div>Footer</div>}
                        bordered
                        dataSource={data.kelasMerek}
                        renderItem={(item) => <List.Item>{`${item.kodeKelas} - ${item.kelas}`}</List.Item>}
                    />
                </Col>
            </Row>
        </Card>
    );
}