import { all, call, fork, put, StrictEffect, takeEvery } from "redux-saga/effects";
// import {
//     auth,
//     facebookAuthProvider,
//     githubAuthProvider,
//     googleAuthProvider,
//     twitterAuthProvider
// } from "../../firebase/firebase";
import {
    LOAD_PROFILE,
    SIGNIN_FACEBOOK_USER,
    SIGNIN_GITHUB_USER,
    SIGNIN_GOOGLE_USER,
    SIGNIN_TWITTER_USER,
    SIGNIN_USER,
    SIGNOUT_USER,
    SIGNUP_USER
} from "../../constants/ActionTypes";
import { showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess } from "../../appRedux/actions/Auth";
import {
    userFacebookSignInSuccess,
    userGithubSignInSuccess,
    userGoogleSignInSuccess,
    userTwitterSignInSuccess
} from "../actions/Auth";
import { ApiGet,                                                        ApiPost } from "../../util/ApiClient";
import { TOKEN_KEY } from "../../constants/AppConstants";
import { UserLogin } from "src/routes/Auth/SignIn";
import { loadMasterDataSuccess, resetMasterData } from "../actions";
// import { Action } from "redux";

// const createUserWithEmailPasswordRequest = async (email, password) =>
//     await auth.createUserWithEmailAndPassword(email, password)
//         .then(authUser => authUser)
//         .catch(error => error);

// const signInUserWithEmailPasswordRequest = async (email, password) =>
//   await auth.signInWithEmailAndPassword(email, password)
//     .then(authUser => authUser)
//     .catch(error => error);

// const signOutRequest = async () =>  await ApiPost("/logout", null);

// const signInUserWithGoogleRequest = async () =>
//     await auth.signInWithPopup(googleAuthProvider)
//         .then(authUser => authUser)
//         .catch(error => error);

// const signInUserWithFacebookRequest = async () =>
//     await auth.signInWithPopup(facebookAuthProvider)
//         .then(authUser => authUser)
//         .catch(error => error);

// const signInUserWithGithubRequest = async () =>
//     await auth.signInWithPopup(githubAuthProvider)
//         .then(authUser => authUser)
//         .catch(error => error);

// const signInUserWithTwitterRequest = async () =>
//     await auth.signInWithPopup(twitterAuthProvider)
//         .then(authUser => authUser)
//         .catch(error => error);

// function* createUserWithEmailPassword({ payload }) {
//     const { email, password } = payload;
//     try {
//         const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
//         if (signUpUser.message) {
//             yield put(showAuthMessage(signUpUser.message));
//         } else {
//             localStorage.setItem('user_id', signUpUser.user.token);
//             yield put(userSignUpSuccess(signUpUser.user.token));
//         }
//     } catch (error) {
//         yield put(showAuthMessage(error));
//     }
// }

// function* signInUserWithGoogle() {
//     try {
//         const signUpUser = yield call(signInUserWithGoogleRequest);
//         if (signUpUser.message) {
//             yield put(showAuthMessage(signUpUser.message));
//         } else {
//             localStorage.setItem('user_id', signUpUser.user.token);
//             yield put(userGoogleSignInSuccess(signUpUser.user.token));
//         }
//     } catch (error) {
//         yield put(showAuthMessage(error));
//     }
// }


// function* signInUserWithFacebook() {
//     try {
//         const signUpUser = yield call(signInUserWithFacebookRequest);
//         if (signUpUser.message) {
//             yield put(showAuthMessage(signUpUser.message));
//         } else {
//             localStorage.setItem('user_id', signUpUser.user.token);
//             yield put(userFacebookSignInSuccess(signUpUser.user.token));
//         }
//     } catch (error) {
//         yield put(showAuthMessage(error));
//     }
// }


// function* signInUserWithGithub() {
//     try {
//         const signUpUser = yield call(signInUserWithGithubRequest);
//         if (signUpUser.message) {
//             yield put(showAuthMessage(signUpUser.message));
//         } else {
//             localStorage.setItem('user_id', signUpUser.user.token);
//             yield put(userGithubSignInSuccess(signUpUser.user.token));
//         }
//     } catch (error) {
//         yield put(showAuthMessage(error));
//     }
// }


// function* signInUserWithTwitter() {
//     try {
//         const signUpUser = yield call(signInUserWithTwitterRequest);
//         if (signUpUser.message) {
//             if (signUpUser.message.length > 100) {
//                 yield put(showAuthMessage('Your request has been canceled.'));
//             } else {
//                 yield put(showAuthMessage(signUpUser.message));
//             }
//         } else {
//             localStorage.setItem('user_id', signUpUser.user.token);
//             yield put(userTwitterSignInSuccess(signUpUser.user.token));
//         }
//     } catch (error) {
//         yield put(showAuthMessage(error));
//     }
// }

function* loadProfileRequest(): any {
    const response = yield ApiGet("/user/profile", { withCredentials: true });
    if (response.success) {
        return { ...response.data };
    } else {
        return { code: "auth/failed-load-profile", message: response.message, a: null };
    }
}

function* loadProfile(): any {
    try {
        const profile: any = yield call(loadProfileRequest);
        if (profile.message) {
            yield put(showAuthMessage(profile.message));
        } else {
            localStorage.setItem(TOKEN_KEY, profile.token);
            yield put(userSignInSuccess(profile));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signOutRequest(): any {
    const response = yield ApiPost("/logout", {});
    if (response.success) {
        return { ...response.data };
    } else {
        return { code: "auth/failed-logout", message: response.message, a: null };
    }
}

function* signOut(): any {
    try {
        localStorage.removeItem(TOKEN_KEY);
        yield put(userSignInSuccess(null));
        yield put(resetMasterData());
        call(signOutRequest);
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

// export function* createUserAccount() {
//     yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
// }

// export function* signInWithGoogle() {
//     yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
// }

// export function* signInWithFacebook() {
//     yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
// }

// export function* signInWithTwitter() {
//     yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
// }

// export function* signInWithGithub() {
//     yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
// }

function* signInUserWithEmailPasswordRequest(email: string, password: string): any {
    const response = yield ApiPost("/user/login", { username: email, password });
    if (response.success) {
        return { ...response.data.data };
    } else {
        return { code: "auth/wrong-password", message: response.message, a: null };
    }
}

export type LoginFetchType = {
    payload: UserLogin;
    type: string;
};

function* signInUserWithEmailPassword({ payload }: LoginFetchType): any {
    const { email, password } = payload;
    try {
        const signInUser: any = yield call(signInUserWithEmailPasswordRequest, email, password);
        if (signInUser.message) {
            yield put(showAuthMessage(signInUser.message));
        } else {
            localStorage.setItem(TOKEN_KEY, signInUser.token);
            yield put(userSignInSuccess(signInUser));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export function* loadProfileUser() {
    yield takeEvery(LOAD_PROFILE, loadProfile);
}

export default function* rootSaga() {
    yield all([
        fork(signInUser),
        // fork(createUserAccount),
        // fork(signInWithGoogle),
        // fork(signInWithFacebook),
        // fork(signInWithTwitter),
        // fork(signInWithGithub),
        fork(signOutUser),
        fork(loadProfileUser)
    ]);
}
