import {
    LOAD_MENU_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
    menuTree: []
};

const SystemReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case LOAD_MENU_SUCCESS: {
            // console.log("XXXXXXXXXX ==> SystemReducer ==> LOAD_MENU_SUCCESS");
            return {
                ...state,
                menuTree: action.payload
            }
        }
        default:
            return state;
    }
}

export default SystemReducer;
