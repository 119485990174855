import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import CustomScrollbars from "../../util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import { loadMenu } from "../../appRedux/actions";

const SubMenu = Menu.SubMenu;

const SidebarContent: FC<any> = ({ sidebarCollapsed, setSidebarCollapsed }) => {

  const dispatch = useDispatch();
  const { navStyle, themeType } = useSelector(({ settings }: any) => settings);
  // const { menuTree } = useSelector((state: any) => state["system"]);
  const { authUser } = useSelector(({ auth }: { auth: any }) => auth);
  const pathname = useSelector(({ common }: any) => common.pathname);
  const location = useLocation();

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    dispatch(await loadMenu());
  };

  const getNoHeaderClass = (navStyle: any) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle: any) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const [openParentKeys, setOpenParentKeys] = useState<Array<any>>([]);
  const [selectedMenu, setSelectedMenu] = useState("");
  const mapParents = useRef<{ [key: string]: any }>({});
  const openParentKeysRef = useRef<Array<any>>([]);

  const onSelectedMenu = useCallback((e: any) => {

    const menuCode = e.key;
    const elTarget = e.domEvent.target.parentElement.parentElement;

    if (!mapParents.current[menuCode]) { // If Is Not Parent
      setSelectedMenu(menuCode);
    }

    const newOpenKeys = openParentKeysRef.current;
    let existIndex = newOpenKeys.findIndex(k => k === menuCode);
    if (existIndex !== -1) {
      if (elTarget.children[1]) {
        elTarget.children[1].classList.add("ant-menu-hidden");
        elTarget.children[1].setAttribute("style", "height: 0px; opacity: 0;");
      }
      elTarget.classList.remove("ant-menu-submenu-open");
      elTarget.classList.remove("ant-menu-submenu-active");
      elTarget.children[0].setAttribute("aria-expanded", false);

      newOpenKeys.splice(existIndex, 1);
      existIndex = -1;
      openParentKeysRef.current = newOpenKeys;
    } else {

      let parent: any = null;
      if (mapParents.current[menuCode]) {
        openParentKeysRef.current = openParentKeysRef.current.concat([menuCode]);
        parent = mapParents.current[mapParents.current[menuCode].parentCode];

        if (elTarget.children[1]) {
          elTarget.children[1].classList.remove("ant-menu-hidden");
          elTarget.children[1].setAttribute("style", "height: auto; opacity: 1;");
        }
        elTarget.classList.add("ant-menu-submenu-open");
        elTarget.classList.add("ant-menu-submenu-active");
        elTarget.children[0].setAttribute("aria-expanded", true);

      }
    }

    setOpenParentKeys(openParentKeysRef.current);
  }, [selectedMenu, mapParents.current, openParentKeys, openParentKeysRef.current]);

  const RenderMenuTree = useCallback((menu: Array<any>) => {
    return menu.map((item: any) => {
      if (!mapParents.current[item.menuCode] && item.children && item.children.length > 0) {
        const newMapRoots = {
          ...mapParents.current,
          [item.menuCode]: {
            menuId: item.menuId,
            menuCode: item.menuCode,
            parentId: item.parentId,
            parentCode: item.parentCode,
            childsParentCode: item.children.filter((o: any) => (o.children && o.children.length > 0)).map((o: any) => o.menuCode)
          }
        };
        mapParents.current = newMapRoots;
      }

      const isActive = pathname === item.menuPath;
      const itemClass = isActive ? "ant-menu-item-active" : "";
      const isParent = item.children && item.children.length > 0;

      if (isActive) {
        if (item.menuCode !== selectedMenu) {
          setSelectedMenu(item.menuCode);
          openParentKeysRef.current = [];
        }

        let parent = mapParents.current[item.parentCode];
        while (parent && !openParentKeysRef.current.find(k => k === parent.menuCode)) {
          const newParents = openParentKeysRef.current.concat([parent.menuCode]);
          openParentKeysRef.current = newParents;
          setOpenParentKeys(newParents);
          parent = mapParents.current[parent.parentCode];
        }
      }

      return (
        isParent ?
          <SubMenu key={item.menuCode} popupClassName={getNavStyleSubMenuClass(navStyle)}
            onTitleClick={onSelectedMenu}
            title={
              <span>
                <i className="icon icon-data-display" />
                <span>{item.translateCode ? <IntlMessages id={item.translateCode} /> : item.menuName}</span>
              </span>
            }>
            {RenderMenuTree(item.children)}
          </SubMenu> :
          <Menu.Item key={item.menuCode} className={itemClass} onClick={onSelectedMenu} >
            <Link to={item.menuPath}><i className="icon icon-widgets" />
              <span>{item.menuName}</span>
            </Link>
          </Menu.Item>
      );
    });
  }, [mapParents.current, openParentKeysRef.current, openParentKeys, pathname, selectedMenu]);

  // const RenderMenu = useMemo(() => RenderMenuTree(menuTree), [selectedMenu, menuTree, openParentKeys]);

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          {/* <Menu
            defaultOpenKeys={openParentKeys}
            openKeys={openParentKeys}
            theme={themeType === THEME_TYPE_LITE ? "light" : "dark"}
            mode="inline">
            {RenderMenu}
          </Menu> */}
          <Menu
            defaultOpenKeys={openParentKeys}
            openKeys={openParentKeys}
            theme={themeType === THEME_TYPE_LITE ? "light" : "dark"}
            mode="inline">
            {/* 
            <Menu.ItemGroup key="dashboard" className="ant-menu-item-group gx-menu-group" title="">
              <Menu.Item key="DASHBOARD" className={location.pathname === "/dashboard" ? "ant-menu-item-active" : ""} onClick={onSelectedMenu} >
                <Link to="/dashboard"><i className="icon icon-chart" /><span>Dashboard</span></Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="master" className="ant-menu-item-group gx-menu-group" title="MASTER">
              {RenderMenu}
              <Menu.Item key="MS-LAYANAN" className={location.pathname === "/service" ? "ant-menu-item-active" : ""} onClick={onSelectedMenu} >
                <Link to="/service"><i className="icon icon-editor" /><span>Layanan</span></Link>
              </Menu.Item>
              <Menu.Item key="MS-DISKON" className={location.pathname === "/diskon" ? "ant-menu-item-active" : ""} onClick={onSelectedMenu} >
                <Link to="/diskon"><i className="icon icon-widgets" /><span>Diskon</span></Link>
              </Menu.Item>
              <Menu.Item key="MS-USER" className={location.pathname === "/user" ? "ant-menu-item-active" : ""} onClick={onSelectedMenu} >
                <Link to="/user"><i className="icon icon-contacts" /><span>User</span></Link>
              </Menu.Item>
            </Menu.ItemGroup> */}

            <Menu.ItemGroup key="master" className="ant-menu-item-group gx-menu-group" title="MASTER">
              {
                authUser?.type === "root" &&
                <>
                  <Menu.Item key="MASTER-USER" className={location.pathname.split("/")[1] === "user" ? "ant-menu-item-active" : ""} onClick={onSelectedMenu} >
                    <Link to="/user"><i className="icon icon-contacts" /><span>Admin</span></Link>
                  </Menu.Item>
                  <Menu.Item key="MASTER-LAYANAN" className={location.pathname.split("/")[1] === "service" ? "ant-menu-item-active" : ""} onClick={onSelectedMenu} >
                    <Link to="/service"><i className="icon icon-widgets" /><span>Layanan</span></Link>
                  </Menu.Item>
                  <Menu.Item key="MASTER-PARTNER" className={location.pathname.split("/")[1] === "partner" ? "ant-menu-item-active" : ""} onClick={onSelectedMenu} >
                    <Link to="/partner"><i className="icon icon-editor" /><span>Mitra</span></Link>
                  </Menu.Item>
                </>
              }
              <Menu.Item key="MS-DISKON" className={location.pathname.split("/")[1] === "discount" ? "ant-menu-item-active" : ""} onClick={onSelectedMenu} >
                <Link to="/discount"><i className="icon icon-widgets" /><span>Diskon</span></Link>
              </Menu.Item>
            </Menu.ItemGroup>

            {/* <Menu.Item key="MASTER-CUSTOMER" className={location.pathname.split("/")[1] === "customer" ? "ant-menu-item-active" : ""} onClick={onSelectedMenu} >
                <Link to="/customer"><i className="icon icon-editor" /><span>Pelanggan</span></Link>
              </Menu.Item> */}

            <Menu.ItemGroup key="transaction" className="ant-menu-item-group gx-menu-group" title="TRANSAKSI">
              <Menu.Item key="TRANSAKSI-ORDER" className={location.pathname.split("/")[1] === "order" ? "ant-menu-item-active" : ""} onClick={onSelectedMenu} >
                <Link to="/order"><i className="icon icon-shopping-cart" /><span>Pesanan</span></Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default SidebarContent;