import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import systemSagas from "./System";
import masterDataSagas from "./MasterData";


export default function* rootSaga(_getState: any) {
    yield all([
        authSagas(),
        systemSagas(),
        masterDataSagas()
    ]);
}
