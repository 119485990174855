import React, { useState } from "react";
import SearchBox from "src/components/SearchBox";
import Modal from "antd/lib/modal/Modal";
import SelectOption from "src/components/SelectOption";
import { Table, Card, Row, Col, Button, Form, Input } from "antd";
import { ApiGet, ApiPost, ApiPut } from "src/util/ApiClient";
import { formatRupiah } from "src/util/Formater";
import { useLocation, useNavigate } from "react-router-dom";
import { setCurrentRoutes } from "src/appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useApiErrorAlert } from "src/hooks/user";
import { IPagination, IDiscountModel, defaultDiscountValue } from "src/type-interface/model";
import { CustomizedForm } from "src/components/Form/CustomizedForm";
import { PartnerSelect } from "src/components/Shared/SelectComponents";
import { history } from "src/appRedux/store";

const statusOptions: Array<{ label: string, value: string }> = [{ label: "Active", value: "active" }, { label: "Tidak Aktif", value: "inactive" }];
const discountTypeOptions: Array<{ label: string, value: string }> = [{ label: "Persen", value: "percentage" }, { label: "Nominal", value: "nominal" }];

export const getStatusName = (status: string): string => {
  return statusOptions.find(o => o.value === status)?.label || "";
}

interface IFilter {
  keywords: string;
  partner_code: string;
  discount_type: "" | "percentage" | "nominal";
  status: "" | "active" | "inactive";
}

const columns = [
  {
    title: "DISCOUNT CODE",
    dataIndex: "discount_code",
    key: "discount_code",
    width: 90,
    render: (val: any) => <span>{val}</span>,
  },
  {
    title: "DISCOUNT NAME",
    dataIndex: "discount_name",
    key: "discount_name",
    width: 125,
    render: (val: string) => <span>{val}</span>,
  },
  {
    title: "VALUE",
    dataIndex: "discount_value",
    key: "discount_value",
    width: 100,
    render: (val: string, row: IDiscountModel) => <span>{`${row.discount_type === "percentage" ? val + "%" : formatRupiah(val)} `}</span>,
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    width: 100,
    render: (val: string) => <span>{getStatusName(val)}</span>,
  }
];

export const DiscountListPage: React.FC<any> = () => {

  const [discountList, setDiscountList] = useState<Array<IDiscountModel>>([]);
  const [filter, setFilter] = React.useState<IFilter>({ partner_code: "", discount_type: "", status: "", keywords: "" });
  const [pagination, setPagination] = React.useState<IPagination>({});
  const { listPartner } = useSelector(({ masterData }: { masterData: any }) => masterData);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiErrorAlert = useApiErrorAlert();
  const onChangeFilter = React.useCallback((key: string, value: string) => {
    const newFilter = { ...filter, [key]: value };
    loadDiscountList(getQueryString(undefined, newFilter));
  }, [filter, pagination]);

  const getQueryParams = React.useCallback((query?: string) => {
    const queryParams = new URLSearchParams(query || location.search);
    let params: any = {};
    queryParams.forEach((v, k) => params[k] = v);
    return params;
  }, [location]);

  const getQueryString = React.useCallback((newPagination?: IPagination, newFilter?: any) => {
    return ("?" + new URLSearchParams({
      ...(newPagination || pagination),
      ...(newFilter || filter)
    }).toString());
  }, [filter, pagination]);

  const loadDiscountList = React.useCallback(async (query: string) => {
    const res = await ApiGet(`/discount/list${query || ""}`);
    if (res.data && Array.isArray(res.data.data)) {
      setDiscountList([...res.data.data]);
      const resPagination = res.data.pagination;
      const newQuery = getQueryParams(query);
      const newFilter = {
        ...filter,
        partner_code: newQuery.partner_code || "",
        discount_type: newQuery.discount_type || "",
        status: newQuery.status || "",
        keywords: newQuery.keywords || ""
      };
      const newPagination = { perPage: resPagination.perPage, currentPage: resPagination.currentPage, total: resPagination.total };
      const url = getQueryString(newPagination, newFilter);
      setPagination(newPagination);
      setFilter(newFilter);
      history.replace(`/discount${url}`, null);
    } else if (!res.success) {
      apiErrorAlert(res);
    }
  }, [discountList, filter, pagination]);

  React.useEffect(() => {
    loadDiscountList(location.search);
    dispatch(setCurrentRoutes([
      {
        breadcrumbName: "Master",
      },
      {
        path: "/user",
        breadcrumbName: "Admin",
      }
    ]));
  }, [location.search]);

  const { authUser } = useSelector(({ auth }: { auth: any }) => auth);

  const [form] = Form.useForm<IDiscountModel>();
  const [visibleDetail, setVisibleDetail] = React.useState<boolean>(false);

  const onSubmitDetail = React.useCallback(async () => {

    if (form.getFieldValue("discount_id") === 0 && listPartner.length === 1) {
      form.setFieldValue("partner_code", listPartner[0].partner_code);
    }

    const val = form.getFieldsValue();
    let res;
    if (val.discount_id === 0) {
      res = await ApiPost("/discount/create", val);
    } else {
      res = await ApiPut(`/discount/update/${val.discount_id}`, val);
    }
    if (res.data.success) {
      loadDiscountList(location.search);
    }
    apiErrorAlert(res, () => setVisibleDetail(false));
  }, [form, visibleDetail, listPartner]);

  const onShowModalDetail = React.useCallback(async (discount_id: number) => {
    const res = await ApiGet(`/discount/detail/${discount_id}`);
    if (res.data) {
      form.setFieldsValue(res.data.data);
      setVisibleDetail(true);
    } else {
      apiErrorAlert(res);
    }
  }, [form, visibleDetail, authUser]);

  const onNewDiscount = React.useCallback(async (e: any) => {
    const partner_code = listPartner.length > 0 ? listPartner[0].partner_code : "";
    form.setFieldsValue({ ...defaultDiscountValue, discount_type: "nominal", status: "inactive", partner_code });
    setVisibleDetail(true);
  }, [form, visibleDetail]);

  return (
    <>
      <Modal
        title={form.getFieldValue("discount_id") === 0 ? "Tambah Diskon Baru" : "Detail Diskon"}
        open={visibleDetail}
        width={500}
        footer={
          // <div>
          //   <Button type="text" onClick={() => setVisibleDetail(!visibleDetail)}>Batal</Button>
          <Button type="primary" onClick={onSubmitDetail}>Submit</Button>
          // </div>
        }
        onCancel={() => setVisibleDetail(false)}>
        <CustomizedForm
          debounce={500}
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          name="basic"
          form={form}
          className="gx-signin-form gx-form-row">
          <Form.Item name="discount_id" hidden />

          <Form.Item
            hidden={listPartner.length === 1}
            label="Mitra"
            rules={[
              { required: true, message: "Input Kode Mitra!" },
            ]}
            name="partner_code">
            <PartnerSelect defaultValue={form.getFieldValue("partner_code")} placeholder="Pilih Mitra" />
          </Form.Item>

          <Form.Item
            label="Kode Diskon"
            rules={[
              { required: true, message: "Input Kode Diskon!" },
            ]}
            name="discount_code">
            <Input placeholder="Kode Diskon" />
          </Form.Item>
          <Form.Item
            label="Nama Diskon"
            rules={[
              { required: true, message: "Input Nama Diskon!" }
            ]}
            name="discount_name">
            <Input placeholder="Nama Diskon" />
          </Form.Item>
          <Form.Item
            label="Tipe Diskon"
            rules={[
              { required: true, message: "Pilih Tipe Diskon!" }
            ]}
            name="discount_type">
            <SelectOption options={discountTypeOptions} />
          </Form.Item>
          <Form.Item
            label="Jumlah Diskon"
            rules={[
              { required: true, message: "Input Jumlah Diskon!" }
            ]}
            name="discount_value">
            <Input placeholder="Jumlah Diskon" />
          </Form.Item>
          <Form.Item
            label="Status"
            rules={[
              { required: true, message: "Pilih Status!" }
            ]}
            name="status">
            <SelectOption options={statusOptions} />
          </Form.Item>
        </CustomizedForm>
      </Modal>
      <Row style={{ rowGap: 0 }}>
        <Col className="ant-col-24">
          <Card title={
            <Row>
              <Col className="ant-col-18">
                <div style={{ display: "flex", flexDirection: "row", paddingTop: 12, paddingLeft: 6 }}>
                  <div style={{ display: "flex", flexDirection: "column", width: 200, marginRight: 24 }}>
                    <label style={{ height: 32 }}>Mitra</label>
                    <PartnerSelect value={filter.partner_code} onChange={(val: string) => onChangeFilter("partner_code", val)} addAllOption={authUser?.type === "root"} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", width: 200, marginRight: 24 }}>
                    <label style={{ height: 32 }}>Tipe Diskon</label>
                    <SelectOption
                      value={filter.discount_type}
                      onChange={(val) => onChangeFilter("discount_type", val)}
                      options={discountTypeOptions}
                      addAllOption={true} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", width: 200, marginRight: 24 }}>
                    <label style={{ height: 32 }}>Status</label>
                    <SelectOption
                      value={filter.status}
                      onChange={(val) => onChangeFilter("status", val)}
                      options={statusOptions}
                      addAllOption={true} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", width: 300 }}>
                    <label style={{ height: 32 }}>Pencarian</label>
                    <SearchBox
                      placeholder="Kata Kunci..."
                      onChange={(e: any) => onChangeFilter("keywords", e.target.value)}
                      value={filter.keywords} />
                  </div>
                </div>
              </Col>
              <Col className="ant-col-6 text-right">
                <Button type="primary" onClick={onNewDiscount}>Tambah Diskon</Button>
              </Col>
            </Row>
          }>
            <Table
              className="gx-table-responsive"
              columns={columns}
              dataSource={discountList}
              pagination={{
                pageSize: Number(pagination.perPage),
                total: Number(pagination.total),
                defaultCurrent: 0,
                defaultPageSize: 10,
                current: Number(pagination.currentPage),
                showSizeChanger: true
              }}
              tableLayout="auto"
              scroll={{ y: 425, x: "max-content" }}
              onChange={(valPagination: any) => {
                const newPagination = {
                  perPage: Number(valPagination.pageSize),
                  currentPage: Number(valPagination.current)
                };
                navigate(`/user${getQueryString(newPagination)}`);
              }}
              onRow={(record) => {
                return { onClick: () => onShowModalDetail(record.discount_id) };
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};