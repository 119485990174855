import React from "react";
import { Form, FormProps } from "antd";
import { useCustomDebounce } from "src/hooks/general";

interface CustomizedFormProps extends FormProps {
    onChangedField?: (key: string, value: any) => void;
    debounce?: number;
}

export const CustomizedForm: React.FC<{ children?: React.ReactNode; } & CustomizedFormProps> = (props) => {
    const [form] = Form.useForm();
    const { debouncedValue, onSetValue } = useCustomDebounce(props.debounce || 0);
    const onFieldsChange = React.useCallback((changed: any, fields: any) => {
        onSetValue(changed[0].name[0], changed[0].value);
    }, [form]);

    React.useEffect(() => {
        const keys = Object.keys(debouncedValue);
        if (keys.length > 0) {
            keys.forEach(key => {
                // console.log("Changed ----> key: " + key, debouncedValue);
                if (props.onChangedField) {
                    props.onChangedField(key, debouncedValue[key]);
                }
            });
        }
    }, [debouncedValue]);

    return (
        <Form {...props} onFieldsChange={onFieldsChange} />
    );
}
