import React from "react";
import { useSelector } from "react-redux";
import { ICustomerModel, IPartnerModel } from "src/type-interface/model";
import { ApiGet } from "src/util/ApiClient";
import { useApiErrorAlert } from "./user";

export const ServiceGroupName: React.FC<{ service_group_code: string }> = ({ service_group_code }) => {
    const { listServiceGroup } = useSelector(({ masterData }: { masterData: any }) => masterData);
    return <span>{(listServiceGroup || []).find((o: any) => o.service_group_code === service_group_code)?.service_group_name}</span>
}

export const CustomerName: React.FC<{ id: number }> = ({ id }) => {

    const [customer, setCustomer] = React.useState<ICustomerModel>();
    const apiErrorAlert = useApiErrorAlert();

    React.useEffect(() => {
        (async () => {
            const res = await ApiGet(`/customer/detail/${id}`);
            if (res.data) {
                setCustomer(res.data.data);
            } else if (!res.success) {
                apiErrorAlert(res);
            }
        })();
    }, []);
    return <span>{customer && customer.full_name}</span>
}

export const PartnerName: React.FC<{ partner_code: string }> = ({ partner_code }) => {
    const { listPartner } = useSelector(({ masterData }: { masterData: any }) => masterData);
    return <span>{(listPartner || []).find((o: any) => o.partner_code === partner_code)?.name}</span>
}