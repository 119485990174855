import React from "react";
import TextArea from "antd/lib/input/TextArea";
import { useNavigate, useParams, useLocation } from "react-router";
import { ApiGet, ApiPost, ApiPut } from "src/util/ApiClient";
import { Button, Card, Checkbox, Col, Divider, Form, Input, Image as AntImage, Row, Tabs, Tag, Upload, Space, Select, Table } from "antd";
import { useApiErrorAlert } from "src/hooks/user";
import { defaultPartnerValue, IPartnerModel, IPartnerServiceModel, IServiceGroupModel } from "src/type-interface/model";
import { useDispatch, useSelector } from "react-redux";
import { loadMasterData, setCurrentRoutes } from "src/appRedux/actions";
import { Link } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { showConfirm } from "src/util/Confirm";
import { history } from "src/appRedux/store";
import { formatRupiah } from "src/util/Formater";

const RenderTableColumn = (props: {
  service_group_code: string;
  onChangeSelectAllService: (service_group_code: string, checked: boolean) => void;
  isActiveServiceGroup: (service_group_code: string) => boolean;
  onChangePartnerService: (service_id: number, key: string, val: any) => void;
}) => {
  const { service_group_code, onChangeSelectAllService, isActiveServiceGroup, onChangePartnerService } = props;
  return [
    {
      title: <Checkbox disabled={!isActiveServiceGroup(service_group_code)} onChange={((e) => onChangeSelectAllService(service_group_code, e.target.checked))} />,
      dataIndex: "checked",
      key: "checked",
      width: 60,
      render: (val: number, row: IPartnerServiceModel) => <Checkbox disabled={!isActiveServiceGroup(row.service_group_code)} checked={val === 1} onChange={((e) => onChangePartnerService(row.service_id, "checked", e.target.checked))} />,
    },
    {
      title: "NAMA LAYANAN",
      dataIndex: "name",
      key: "name",
      width: "auto",
      render: (val: string) => <span>{val}</span>,
    },
    {
      title: "HARGA DASAR",
      dataIndex: "price",
      key: "price",
      width: 140,
      render: (val: number) => <span>{formatRupiah(val)}</span>,
    },
    {
      title: "TIPE MARGIN",
      dataIndex: "margin_type",
      key: "margin_type",
      width: 90,
      render: (val: "percentage" | "nominal", row: IPartnerServiceModel) => <Select
        id="margin_type"
        value={val}
        size="middle"
        defaultValue="nominal"
        onChange={(o) => onChangePartnerService(row.service_id, "margin_type", o)}
        options={[{ label: "Nominal", value: "nominal" }, { label: "Percentage", value: "percentage" }]}
        dropdownMatchSelectWidth={false}
        disabled={!isActiveServiceGroup(row.service_group_code)} />
    },
    {
      title: "MARGIN",
      dataIndex: "margin_value",
      key: "margin_value",
      width: 140,
      render: (val: number, row: IPartnerServiceModel) => <Input disabled={!isActiveServiceGroup(row.service_group_code)} value={val} onChange={(e) => onChangePartnerService(row.service_id, "margin_value", e.target.value)} />
    },
    {
      title: "DISPLAY",
      dataIndex: "show_price_landing_page",
      key: "show_price_landing_page",
      width: 70,
      align: "center" as const,
      render: (val: number, row: IPartnerServiceModel) => <Checkbox disabled={!isActiveServiceGroup(row.service_group_code)} checked={val === 1} onChange={((e) => onChangePartnerService(row.service_id, "show_price_landing_page", e.target.checked))} />,
    }
  ];
}

const PartnerDetailPage: React.FC = () => {

  const { id } = useParams();
  const [isSaveLoading, setIsSaveLoading] = React.useState<boolean>(false);
  const [partner, setPartner] = React.useState<IPartnerModel>(defaultPartnerValue);
  const apiErrorAlert = useApiErrorAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { listServiceGroup } = useSelector(({ masterData }: { masterData: any }) => masterData);

  const loadPartner = React.useCallback(async () => {

    if (!id && !partner.partner_code) return;

    const res = await ApiGet(`/partner/detail/${id || partner.partner_code}`);
    if (res.data) {
      setPartner(res.data.data);
    } else if (!res.success) {
      apiErrorAlert(res);
    }
  }, [partner, id]);

  React.useEffect(() => {
    const qparam: any = new URLSearchParams(location.search);
    if (partner.partner_code && qparam && qparam.get("aftercreate") === "1") {
      history.replace(`/partner/${partner.partner_code}`, location.state?.from);
      setTimeout(() => {
        const element = document.getElementById("partner-service");
        element?.scrollIntoView();
      }, 500);
    }
  }, [location.search, partner.partner_code]);

  React.useEffect(() => {
    loadPartner();
    dispatch(setCurrentRoutes([
      {
        breadcrumbName: "Master",
      },
      {
        path: location.state?.from || "/partner",
        breadcrumbName: "Daftar Mitra",
      },
      {
        path: "",
        breadcrumbName: "Detail Mitra",
      }
    ]));
  }, []);

  const onSaveData = React.useCallback(async () => {

    if (!partner.partner_code) {
      showConfirm({ title: "Peringatan!", icon: "warning", message: "Kode Mitra harus diisi!" });
      return;
    }

    if (!partner.domain) {
      showConfirm({ title: "Peringatan!", icon: "warning", message: "Domain harus diisi!" });
      return;
    }

    if (partner.config_json.service_group.length === 0) {
      showConfirm({ title: "Peringatan!", icon: "warning", message: "Mohon pilih Grup Layanan!" });
      return;
    }

    setIsSaveLoading(true);
    let res;
    if (id) {
      res = await ApiPut(`/partner/update/${id}`, partner);
    } else {
      res = await ApiPost(`/partner/create`, partner);
    }

    if (res.success) {
      dispatch(loadMasterData());
    }

    if (res.success && !id) {
      res.message = "Berhasil simpan data, selanjutnya Pilih & Aktifkan Layanan!";
    }

    await loadPartner();

    apiErrorAlert(res, !id ? () => navigate(`/partner/${partner.partner_code}?aftercreate=1`) : undefined);

    setIsSaveLoading(false);
  }, [partner, id, isSaveLoading]);

  const onChangeData = React.useCallback(async (key: string, value: any) => {
    let update = { [key]: value };

    if (["logo_square", "logo_rectangular"].includes(key)) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        update[`${key}_filename`] = value.name;
        update[`${key}_file`] = reader.result;
        update[`${key}_url`] = "";
        setPartner({ ...partner, ...update });
      }
    } else {
      setPartner({ ...partner, ...update });
    }
  }, [partner]);


  const onChangeConfig = React.useCallback(async (key: string, value: any) => {
    setPartner({ ...partner, config_json: { ...partner.config_json, [key]: value } });
  }, [partner]);

  const onChangeUpload = React.useCallback(async (key: string, file: any) => {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPartner({
        ...partner,
        config_json: {
          ...partner.config_json,
          [`${key}_filename`]: file.name,
          [`${key}_file`]: reader.result,
          [`${key}_url`]: ""
        }
      });
    }
  }, [partner]);

  const onChangeSelectAllService = React.useCallback((service_group_code: string, checked: boolean) => {

    partner.partner_service.forEach((o) => {
      if (o.service_group_code === service_group_code) {
        o.checked = checked ? 1 : 0;
      }
    });

    setPartner({ ...partner });

  }, [partner]);

  const onChangePartnerService = React.useCallback((service_id: number, key: string, val: any) => {
    const idx = partner.partner_service.findIndex((o) => o.service_id === service_id);
    if (idx !== -1) {
      let update = {};
      if (key === "checked" || key === "show_price_landing_page") {
        update = { [key]: val === true ? 1 : 0 };
      } else {
        update = { [key]: val }
      }
      partner.partner_service[idx] = { ...partner.partner_service[idx], ...update };
      setPartner({ ...partner });
    }
  }, [partner]);

  const isActiveServiceGroup = React.useCallback((service_group_code: string) => {
    const hasIdx = partner.config_json.service_group?.findIndex((x) => x === service_group_code);
    return hasIdx !== -1;
  }, [partner]);

  const onAddRemoveServiceGroup = React.useCallback((service_group_code: string) => {
    if (isActiveServiceGroup(service_group_code)) {
      const hasIdx = partner.config_json.service_group.findIndex((x) => x === service_group_code);
      partner.config_json.service_group.splice(hasIdx, 1);
    } else {
      partner.config_json.service_group.push(service_group_code);
    }
    setPartner({ ...partner });
  }, [partner]);

  const onChangeJsonConfig = React.useCallback((key: string, val: string) => {
    partner.config_json = { ...partner.config_json, [key]: val }
    setPartner({ ...partner });
  }, [partner]);

  return (
    <div className="gx-main-content">
      <Row>
        <Col className="ant-col-24">
          <Card bordered={true} title={
            <Row>
              <Col className="ant-col-12">Data Mitra</Col>
              <Col className="ant-col-12 text-right">
                <Button type="primary" loading={isSaveLoading} onClick={onSaveData}>Simpan</Button>
              </Col>
            </Row>
          }
            style={{ marginBottom: 0 }}>
            <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} colon={false}>
              <Row>
                <Col className="ant-col ant-col-12 ant-col-xs-24 ant-col-xl-12">
                  <Form.Item label="Kode Mitra" htmlFor="partner_code">
                    <Input id="partner_code" disabled={Number(id || 0) !== 0} value={partner.partner_code} onChange={(e) => onChangeData("partner_code", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Nama Mitra" htmlFor="name" rules={[{ required: true, message: "Mohon input nama mitra!" }]}>
                    <Input id="name" value={partner.name} onChange={(e) => onChangeData("name", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Nama Mitra (Singkat)" htmlFor="partner_short_name">
                    <Input id="partner_short_name" value={partner.partner_short_name} onChange={(e) => onChangeData("partner_short_name", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Nama Mitra (Panjang)" htmlFor="partner_long_name">
                    <TextArea id="partner_long_name" value={partner.partner_long_name} rows={2} onChange={(e) => onChangeData("partner_long_name", e.target.value)} />
                  </Form.Item>

                  <Form.Item label="Status" htmlFor="status" tooltip="Apakah mitra mandiri atau kolaborasi?">
                    <Space direction="horizontal" className="ant-col ant-col-24">
                      <Form.Item rules={[{ required: true, message: "Apakah mitra mandiri atau kolaborasi?" }]}>
                        <Checkbox id="independent" checked={partner.independent} onChange={(e) => onChangeData("independent", e.target.checked)}>Website Mandiri</Checkbox>
                      </Form.Item>
                      <Col className="ant-col ant-col-12" style={{ marginLeft: 24 }}>
                        <Form.Item>
                          <Checkbox id="status" checked={partner.status === "active"} onChange={(e) => onChangeData("status", e.target.checked ? "active" : "inactive")}>Aktif</Checkbox>
                        </Form.Item>
                      </Col>
                    </Space>
                  </Form.Item>

                  {
                    !partner.independent &&
                    <Form.Item label="Testimoni Klien KH" htmlFor="status" tooltip="Apakah mitra ingin menampilkan testimoni klien Kontrak Hukum?">
                      <Space direction="horizontal" className="ant-col ant-col-24">
                        <Form.Item rules={[{ required: true, message: "Apakah mitra ingin menampilkan testimoni klien Kontrak Hukum?" }]}>
                          <Checkbox id="show_client_testimony" checked={partner.show_client_testimony} onChange={(e) => onChangeData("show_client_testimony", e.target.checked)}></Checkbox>
                        </Form.Item>
                      </Space>
                    </Form.Item>
                  }

                  <Form.Item label="Alamat" htmlFor="address">
                    <TextArea id="address" value={partner?.config_json?.address} onChange={(e) => onChangeConfig("address", e.target.value)} rows={4} />
                  </Form.Item>
                  <Form.Item label="Tentang Kami" htmlFor="about">
                    <TextArea id="about" value={partner.config_json?.about} onChange={(e) => onChangeConfig("about", e.target.value)} rows={6} />
                  </Form.Item>
                  <Form.Item label="Link Sayarat Ketentuan" htmlFor="terms_conditions_url">
                    <Input id="terms_conditions_url" value={partner.config_json?.terms_conditions_url} onChange={(e) => onChangeConfig("terms_conditions_url", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Link Kebijakan Privasi" htmlFor="privacy_policy_url">
                    <Input id="privacy_policy_url" value={partner.config_json?.privacy_policy_url} onChange={(e) => onChangeConfig("privacy_policy_url", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Grup Layanan" htmlFor="status">
                    {
                      listServiceGroup
                        ?.map((o: IServiceGroupModel) => {
                          return (
                            <Tag
                              key={`service_group_${o.service_group_code}`}
                              onClick={() => onAddRemoveServiceGroup(o.service_group_code)}
                              color={isActiveServiceGroup(o.service_group_code) ? "#87d068" : ""}>
                              <Link to={""}>{o.service_group_name}</Link>
                            </Tag>
                          );
                        })
                    }
                  </Form.Item>
                  <Form.Item label="Video Ulasan Youtube" htmlFor="yt_video_id">
                    <Input id="yt_video_id" placeholder="Input Youtube Video Id" value={partner.config_json?.yt_video_id} onChange={(e) => onChangeConfig("yt_video_id", e.target.value)} />
                  </Form.Item>
                  <Divider dashed orientation="center">Layanan Email</Divider>
                  <Form.Item label="Alamat Pengirim Email" htmlFor="sender_email_as">
                    <Input id="sender_email_as" value={partner?.config_json?.sender_email_as} onChange={(e) => onChangeJsonConfig("sender_email_as", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Email BCC" htmlFor="bcc_emails">
                    <TextArea id="bcc_emails" value={partner?.config_json?.bcc_emails} onChange={(e) => onChangeJsonConfig("bcc_emails", e.target.value)} />
                  </Form.Item>
                </Col>
                <Col className="ant-col ant-col-12 ant-col-xs-24 ant-col-xl-12">
                  <Form.Item label="Domain" htmlFor="domain" rules={[{ required: true, message: "Mohon input domain mitra!" }]}>
                    <Input id="domain" value={partner.domain} onChange={(e) => onChangeData("domain", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Website" htmlFor="partner_website">
                    <Input id="partner_website" value={partner.partner_website} onChange={(e) => onChangeData("partner_website", e.target.value)} />
                  </Form.Item>
                  <Divider dashed orientation="center">Media Sosial</Divider>
                  <Form.Item label="Twitter ID" htmlFor="twitter_id">
                    <Input id="twitter_id" value={partner.config_json?.twitter_id} onChange={(e) => onChangeConfig("twitter_id", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Instagram ID" htmlFor="instagram_id">
                    <Input id="instagram_id" value={partner.config_json?.instagram_id} onChange={(e) => onChangeConfig("instagram_id", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Facebook ID" htmlFor="facebook_id">
                    <Input id="facebook_id" value={partner.config_json?.facebook_id} onChange={(e) => onChangeConfig("facebook_id", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="LinkedIn ID" htmlFor="linkedin_id">
                    <Input id="linkedin_id" value={partner.config_json?.linkedin_id} onChange={(e) => onChangeConfig("linkedin_id", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Logo" htmlFor="logo">
                    {/* <Divider dashed orientation="center">Logo</Divider> */}
                    <Row id="logo">
                      <Col className="ant-col ant-col-8">
                        <Space direction="vertical" className="text-center">
                          <small>Persegi Panjang</small>
                          {
                            partner.logo_rectangular_file || partner.logo_rectangular_url ?
                              <AntImage src={partner.logo_rectangular_file || partner.logo_rectangular_url} style={{ marginBottom: 12 }} />
                              : null
                          }
                          <Upload
                            action=""
                            id="logorec_file"
                            type="select"
                            multiple={false}
                            accept="image"
                            showUploadList={false}
                            onRemove={() => { }}
                            previewFile={undefined}
                            onPreview={undefined}
                            beforeUpload={(file) => {
                              return new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                  const img = new Image();
                                  img.onload = () => {
                                    if (img.width * img.height > 96100) {
                                      // "Icon size max 310x310!"
                                      apiErrorAlert({ success: false, message: "Icon size max 310x310!" });
                                      reject();
                                    } else {
                                      resolve(file);
                                    }
                                  };
                                  img.src = reader.result as string;
                                }
                                reader.onerror = () => {
                                  reject();
                                }
                              });
                            }}
                            onChange={(info) => {
                              if (info.file.status !== "uploading") {
                                onChangeData("logo_rectangular", info.file.originFileObj as File);
                              }
                            }}
                          >
                            <Button style={{ margin: 0 }}>
                              <UploadOutlined /> {partner.logo_rectangular_url ? "Change" : "Upload"}
                            </Button>
                          </Upload>
                        </Space>
                      </Col>
                      <Col className="ant-col ant-col-8">
                        <Space direction="vertical" className="text-center">
                          <small>Kotak / Bulat</small>
                          {
                            partner.logo_square_file || partner.logo_square_url ?
                              <AntImage src={partner.logo_square_file || partner.logo_square_url} style={{ marginBottom: 12 }} />
                              : null
                          }
                          <Upload
                            action=""
                            id="logosqr_file"
                            type="select"
                            multiple={false}
                            accept="image"
                            showUploadList={false}
                            onRemove={() => { }}
                            previewFile={undefined}
                            onPreview={undefined}
                            beforeUpload={(file) => {
                              return new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                  const img = new Image();
                                  img.onload = () => {
                                    if (img.width * img.height > 96100) {
                                      // "Icon size max 310x310!"
                                      apiErrorAlert({ success: false, message: "Icon size max 310x310!" });
                                      reject();
                                    } else {
                                      resolve(file);
                                    }
                                  };
                                  img.src = reader.result as string;
                                }
                                reader.onerror = () => {
                                  reject();
                                }
                              });
                            }}
                            onChange={(info) => {
                              if (info.file.status !== "uploading") {
                                onChangeData("logo_square", info.file.originFileObj as File);
                              }
                            }}
                          >
                            <Button style={{ margin: 0 }}>
                              <UploadOutlined /> {partner.logo_square_url ? "Change" : "Upload"}
                            </Button>
                          </Upload>
                        </Space>
                      </Col>
                      <Col className="ant-col ant-col-8">
                        <Space direction="vertical" className="text-center">
                          <small>Favicon</small>
                          {
                            (partner.config_json?.favicon_file || partner.config_json?.favicon_url) ?
                              <AntImage src={partner.config_json?.favicon_file || partner.config_json?.favicon_url} style={{ marginBottom: 12 }} />
                              : null
                          }
                          <Upload
                            action=""
                            id="favicon_file"
                            type="select"
                            multiple={false}
                            accept="image"
                            showUploadList={false}
                            onRemove={() => { }}
                            previewFile={undefined}
                            onPreview={undefined}
                            beforeUpload={(file) => {
                              return new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                  const img = new Image();
                                  img.onload = () => {
                                    if (img.width * img.height > 96100) {
                                      // "Icon size max 310x310!"
                                      apiErrorAlert({ success: false, message: "Icon size max 310x310!" });
                                      reject();
                                    } else {
                                      resolve(file);
                                    }
                                  };
                                  img.src = reader.result as string;
                                }
                                reader.onerror = () => {
                                  reject();
                                }
                              });
                            }}
                            onChange={(info) => {
                              if (info.file.status !== "uploading") {
                                onChangeUpload("favicon", info.file.originFileObj as File);
                              }
                            }}
                          >
                            <Button style={{ margin: 0 }}>
                              <UploadOutlined /> {partner.config_json.favicon_url ? "Change" : "Upload"}
                            </Button>
                          </Upload>
                        </Space>
                      </Col>
                    </Row>
                    {/* <Divider style={{ marginTop: 30, marginBottom: 30 }}/> */}
                  </Form.Item>

                  <Form.Item label="Inject Script JS" htmlFor="inject_script">
                    <TextArea id="inject_script" placeholder="Contoh: alert('Halooo...');" value={partner.inject_script} onChange={(e) => onChangeData("inject_script", e.target.value)} rows={15} />
                  </Form.Item>
                </Col>
              </Row>
              <Row id="partner-service" style={{ display: !id ? "none" : "block" }}>
                <Col className="ant-col ant-col-24">
                  <Divider dashed orientation="center">Pilih & Aktifkan Layanan</Divider>
                  <Tabs
                    centered
                    // activeKey={(listServiceGroup?.find((o: IServiceGroupModel) => isActiveServiceGroup(o.service_group_code)))?.service_group_code}
                    items={listServiceGroup?.map((o: IServiceGroupModel) => (
                      <Button
                        type="text"
                        disabled={!isActiveServiceGroup(o.service_group_code)}
                        style={{ color: !isActiveServiceGroup(o.service_group_code) ? "rgba(0, 0, 0, 0.40)" : "", marginBottom: 0 }}
                        key={o.service_group_code}>
                        {o.service_group_name}
                      </Button>
                    ))?.map((o: any) => {
                      return {
                        label: o,
                        key: o.key,
                        children: (
                          <Table
                            className="gx-table-responsive"
                            columns={RenderTableColumn({
                              service_group_code: o.key,
                              onChangeSelectAllService,
                              isActiveServiceGroup,
                              onChangePartnerService
                            })}
                            dataSource={partner?.partner_service?.filter((p) => p.service_group_code === o.key) || []}
                            tableLayout="auto"
                            pagination={false}
                            // scroll={{ y: 425, x: "max-content" }}
                          />
                        ),
                      };
                    })} />
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PartnerDetailPage;
