import {
    LOAD_MASTER_DATA_SUCCESS, RESET_MASTER_DATA
} from "../../constants/ActionTypes";

const INIT_STATE = {
    loaded: false,
    listServiceGroup: [],
    listPartner: [],
    listDiskon: [],
    listService: []
};

const MasterDataReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case LOAD_MASTER_DATA_SUCCESS: {
            return {
                ...state,
                ...action.payload
            }
        }
        case RESET_MASTER_DATA: {
            return INIT_STATE;
        }
        default:
            return state;
    }
}

export default MasterDataReducer;
