import { all, fork, put, takeEvery } from "redux-saga/effects";
import { LOAD_MASTER_DATA } from "src/constants/ActionTypes";
import { ApiGet } from "src/util/ApiClient";
import { loadMasterDataSuccess } from "../actions";


export function* fetchMasterData(): any {
    let masterData = {
        loaded: false,
        listServiceGroup: [],
        listPartner: [],
        listDiskon: [],
        listService: []
    };

    let response = yield ApiGet("/service-group/list");
    if (response.success) {
        masterData = {
            ...masterData,
            listServiceGroup: response.data.data
        };
    }

    response = yield ApiGet("/user-partner/list");
    if (response.success) {
        masterData = {
            ...masterData,
            listPartner: response.data.data
        };
    }

    // response = yield ApiGet("/getListDiskon");
    // if (response.success) {
    //     masterData = {
    //         ...masterData,
    //         listDiskon: response.data
    //     };
    // }

    response = yield ApiGet("/service/list");
    if (response.success) {
        masterData = {
            ...masterData,
            listService: response.data.data
        };
    }

    masterData.loaded = true;

    yield put(loadMasterDataSuccess(masterData));
}

export function* loadMasterData() {
    yield takeEvery(LOAD_MASTER_DATA, fetchMasterData);
}

export default function* rootSaga() {
    yield all([
        // fork(loadMasterData),
        takeEvery(LOAD_MASTER_DATA, fetchMasterData)
    ]);
}