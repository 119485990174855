import { SWITCH_LANGUAGE } from "../../constants/ActionTypes";
import {
    LAYOUT_TYPE,
    LAYOUT_TYPE_FULL,
    NAV_STYLE,
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    THEME_COLOR,
    THEME_TYPE,
    THEME_TYPE_SEMI_DARK, UPDATE_RTL_STATUS,
    UPDATE_CURRENT_ROUTES
} from "../../constants/ThemeSetting";

const initialSettings = {
    // navStyle: NAV_STYLE_DRAWER,
    navStyle: NAV_STYLE_FIXED,
    // navStyle: NAV_STYLE_DEFAULT_HORIZONTAL,
    layoutType: LAYOUT_TYPE_FULL,
    themeType: THEME_TYPE_SEMI_DARK,
    themeColor: '',

    isDirectionRTL: false,
    locale: {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us'
    },
    currentRoutes: []
};

const SettingsReducer = (state = initialSettings, action: any) => {
    switch (action.type) {

        case THEME_TYPE:
            return {
                ...state,
                themeType: action.themeType
            };
        case THEME_COLOR:
            return {
                ...state,
                themeColor: action.themeColor
            };

        case UPDATE_RTL_STATUS:
            return {
                ...state,
                isDirectionRTL: action.rtlStatus
            };

        case NAV_STYLE:
            return {
                ...state,
                navStyle: action.navStyle
            };
        case LAYOUT_TYPE:
            return {
                ...state,
                layoutType: action.layoutType
            };

        case SWITCH_LANGUAGE:
            return {
                ...state,
                locale: action.payload
            };
        case UPDATE_CURRENT_ROUTES:
            return {
                ...state,
                currentRoutes: action.currentRoutes
            };
        default:
            return state;
    }
};

export default SettingsReducer;
