import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Form, Input, Modal, Popover } from "antd";
import { userSignOut } from "../../appRedux/actions";
import { ApiPost } from "src/util/ApiClient";
import { useApiErrorAlert } from "src/hooks/user";
import { CustomizedForm } from "src/components/Form/CustomizedForm";

interface IChangePassword { userId: number, password: string; confirmPassword: string; }

const UserProfile: React.FC = () => {

  const dispatch = useDispatch();
  const [visibleChangePassword, setVisibleChangePassword] = React.useState<boolean>(false);
  const { authUser } = useSelector(({ auth }: { auth: any }) => auth);
  const [form] = Form.useForm<IChangePassword>();
  const apiErrorAlert = useApiErrorAlert();

  const onSubmitChangePasswod = React.useCallback(async () => {
    const val = form.getFieldsValue();
    if (val.password !== val.confirmPassword) {
      apiErrorAlert({ success: false, message: "Ulangi Kata Sandi tidak sama!" });
      return;
    }
    const res = await ApiPost("/user/change-password", form.getFieldsValue());
    if (res.data.success) {
      form.resetFields();
    }
    apiErrorAlert(res, () => setVisibleChangePassword(false));
  }, [form, visibleChangePassword]);

  React.useEffect(() => form.setFieldValue("userId", authUser.user_id), [authUser]);

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {/* <li>My Account</li>
      <li>Connections</li> */}
      <li onClick={() => setVisibleChangePassword(!visibleChangePassword)}>Ubah Kata Sandi</li>
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  return (
    <>
      <Modal
        title="Ubah Kata Sandi"
        open={visibleChangePassword}
        footer={
          <div>
            <Button type="text" onClick={() => setVisibleChangePassword(!visibleChangePassword)}>Batal</Button>
            <Button type="primary" onClick={onSubmitChangePasswod}>Simpan</Button>
          </div>
        }
        onCancel={() => setVisibleChangePassword(false)}>
        <CustomizedForm
          debounce={500}
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          name="basic"
          form={form}
          // onFinish={(values: any) => {
          //   console.log("ZZZZZ", values);
          // }}
          // onFinishFailed={(errorInfo: any) => { }}
          className="gx-signin-form gx-form-row">
          <Form.Item name="userId" hidden={true}>
            <Input id="userId" />
          </Form.Item>
          <Form.Item
            label="Kata Sandi Baru"
            rules={[
              { required: true, message: "Please input your Password!" },
              { min: 6, message: "Minimal 6 karakter!" }
            ]} name="password">
            <Input id="password" type="password" placeholder="New Password" autoFocus={true} />
          </Form.Item>
          <Form.Item
            label="Ulangi Kata Sandi"
            rules={[
              { required: true, message: "Please input your Password!" },
              { min: 6, message: "Minimal 6 karakter!" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && value !== getFieldValue("password")) {
                    return Promise.reject("Kata sandi tidak sama!");
                  } else {
                    return Promise.resolve();
                  }
                }
              })
            ]} name="confirmPassword">
            <Input id="confirmPassword" type="password" placeholder="Confirm Password" />
          </Form.Item>
        </CustomizedForm>
      </Modal>
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src={"https://via.placeholder.com/150"} className="gx-size-40 gx-pointer gx-mr-3" alt="" />
          <span className="gx-avatar-name">{authUser.user_name}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
        </Popover>
      </div>
    </>
  )
};

export default UserProfile;
