import React from "react";
import TextArea from "antd/lib/input/TextArea";
import { useParams } from "react-router";
import { ApiGet, ApiPost, ApiPut } from "src/util/ApiClient";
import { Button, Card, Checkbox, Col, Form, Input, Row } from "antd";
import { useApiErrorAlert } from "src/hooks/user";
import { defaultServiceValue, IServiceModel } from "src/type-interface/model";
import { ServiceGroupName } from "src/hooks/order";
import { useDispatch } from "react-redux";
import { setCurrentRoutes } from "src/appRedux/actions";
import { ServiceGroupSelect, ServiceTypeSelect } from "src/components/Shared/SelectComponents";

const ServiceDetailPage: React.FC = () => {

  const { id } = useParams();
  const [isSaveLoading, setIsSaveLoading] = React.useState<boolean>(false);
  const [service, setService] = React.useState<IServiceModel>(defaultServiceValue);
  const apiErrorAlert = useApiErrorAlert();
  const dispatch = useDispatch();

  const loadService = React.useCallback(async () => {

    if (!id) return;

    const res = await ApiGet(`/service/detail/${id}`);
    if (res.data) {
      setService(res.data.data);
    } else if (!res.success) {
      apiErrorAlert(res);
    }
  }, [service]);

  React.useEffect(() => {
    loadService();
    dispatch(setCurrentRoutes([
      {
        breadcrumbName: "Master",
      },
      {
        path: "/service",
        breadcrumbName: "Daftar Layanan",
      },
      {
        path: "",
        breadcrumbName: "Detail Layanan",
      }
    ]));
  }, []);

  const onSaveData = React.useCallback(async () => {
    setIsSaveLoading(true);
    let res;
    if (id) {
      res = await ApiPut(`/service/update/${id}`, service);
    } else {
      res = await ApiPost(`/service/create`, service);
    }
    console.log("ZZZZ-res", res);
    apiErrorAlert(res, loadService);
    setIsSaveLoading(false);
  }, [id, service, isSaveLoading]);

  const onChangeData = React.useCallback((key: string, value: string | number) => {
    let update = { [key]: value };
    setService({ ...service, ...update });
  }, [service]);

  return (
    <div className="gx-main-content">
      <Row>
        <Col className="ant-col-24">
          <Card bordered={true} title={
            <Row>
              {
                id ? <Col className="ant-col-12">Data Layanan - <ServiceGroupName service_group_code={service.service_group_code} /> </Col> :
                  <Col className="ant-col-12">Data Layanan Baru</Col>
              }
              <Col className="ant-col-12 text-right">
                <Button type="primary" loading={isSaveLoading} onClick={onSaveData}>Simpan</Button>
              </Col>
            </Row>
          }
            style={{ marginBottom: 0 }}>
            <Row>
              <Col className="ant-col ant-col-12 ant-col-xs-24 ant-col-xl-12">
                <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                  <Form.Item label="Kode Layanan" htmlFor="service_code">
                    <Input id="service_code" value={service.service_code} onChange={(e) => onChangeData("service_code", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Nama Layanan" htmlFor="name" rules={[{ required: true, message: "Mohon input nama layanan!" }]}>
                    <Input id="name" value={service.name} onChange={(e) => onChangeData("name", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Deskripsi" htmlFor="description">
                    <TextArea id="description" value={service.description} rows={5} onChange={(e) => onChangeData("description", e.target.value)} />
                  </Form.Item>
                </Form>
              </Col>
              <Col className="ant-col ant-col-12 ant-col-xs-24 ant-col-xl-12">
                <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                  <Form.Item label="Tipe Layanan" htmlFor="service_type" rules={[{ required: true, message: "Mohon input nama layanan!" }]}>
                    <ServiceTypeSelect id="service_type" addAllOption={false} value={service.service_type} onChange={(val) => onChangeData("service_type", val)} />
                  </Form.Item>
                  <Form.Item label="Grup Layanan" htmlFor="service_group_code" rules={[{ required: true, message: "Mohon input nama layanan!" }]}>
                    <ServiceGroupSelect id="service_group_code" addAllOption={false} value={service.service_group_code} onChange={(val) => onChangeData("service_group_code", val)} />
                  </Form.Item>
                  <Form.Item label="Harga Dasar" htmlFor="base_price" rules={[{ required: true, message: "Mohon input nama layanan!" }]}>
                    <Input id="base_price" value={service.base_price} onChange={(e) => onChangeData("base_price", e.target.value)} />
                  </Form.Item>
                  <Form.Item label="Highlight" htmlFor="highlight">
                    <Checkbox id="highlight" checked={service.highlight === 1} onChange={(e) => onChangeData("highlight", e.target.checked ? 1 : 0)} />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ServiceDetailPage;
