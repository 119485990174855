import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
    LOAD_MENU
} from "../../constants/ActionTypes";
import { loadMenuSuccess } from "../actions/System";
import { showAuthMessage } from "../actions/Auth";
// import { ApiGet } from "../../util/ApiClient";

// function* fetchMenuApi(): any {
//     const response = yield ApiGet("/menu");
//     if (response.success) {
//         return response.data;
//     } else if (response.messages && response.messages.length > 0) {
//         return { code: "menu/get", message: response.messages[0].message, a: null };
//     }
// }

export function* fetchMenu(): any {
    try {
        // const menu: any = yield call(fetchMenuApi);
        const menu: any = [
            {
                menuId: 1,
                menuCode: "M1",
                menuName: "Dashboard",
                translateCode: "",
                parentId: null,
                parentCode: "",
                children: null,
                menuPath: "/dashboard"
            },
            // {
            //     menuId: 2,
            //     menuCode: "M2",
            //     menuName: "Master Data",
            //     translateCode: "",
            //     parentId: null,
            //     parentCode: "",
            //     children: [
            //         {
            //             menuId: 3,
            //             menuCode: "M2-1",
            //             menuName: "Layanan",
            //             translateCode: "",
            //             parentId: 2,
            //             parentCode: "M2",
            //             children: null,
            //             menuPath: "/layanan"
            //         },
            //         {
            //             menuId: 4,
            //             menuCode: "M2-2",
            //             menuName: "Diskon",
            //             translateCode: "",
            //             parentId: 2,
            //             parentCode: "M2",
            //             children: null,
            //             menuPath: "/diskon"
            //         },
            //         {
            //             menuId: 5,
            //             menuCode: "M2-2",
            //             menuName: "User",
            //             translateCode: "",
            //             parentId: 2,
            //             parentCode: "M2",
            //             children: null,
            //             menuPath: "/user"
            //         }
            //     ],
            //     menuPath: ""
            // },
            {
                menuId: 6,
                menuCode: "M3",
                menuName: "Transaksi",
                translateCode: "",
                parentId: null,
                parentCode: "",
                children: [
                    {
                        menuId: 7,
                        menuCode: "M3-1",
                        menuName: "Pesanan",
                        translateCode: "",
                        parentId: 6,
                        parentCode: "M3",
                        children: null,
                        menuPath: "/order"
                    }
                ],
                menuPath: ""
            },
            // {
            //     menuId: 9,
            //     menuCode: "M4",
            //     menuName: "Menu 4",
            //     translateCode: "",
            //     parentId: null,
            //     parentCode: "",
            //     children: [
            //         {
            //             menuId: 10,
            //             menuCode: "M4-1",
            //             menuName: "Menu 4 - 1",
            //             translateCode: "",
            //             parentId: 9,
            //             parentCode: "M4",
            //             children: null,
            //             menuPath: "/apps/menu4/page1"
            //         },
            //         {
            //             menuId: 11,
            //             menuCode: "M4-2",
            //             menuName: "Menu 4 - 2",
            //             translateCode: "",
            //             parentId: 9,
            //             parentCode: "M4",
            //             children: null,
            //             menuPath: "/apps/menu4/page2"
            //         }
            //     ],
            //     menuPath: ""
            // }
        ];

        if (menu.message) {
            yield put(showAuthMessage(menu.message));
        } else {
            yield put(loadMenuSuccess(menu));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

export function* loadPermission() {
    yield takeEvery(LOAD_MENU, fetchMenu);
}

export default function* rootSaga() {
    yield all([
        // fork(loadPermission),
        takeEvery(LOAD_MENU, fetchMenu)
    ]);
}