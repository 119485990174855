import React from "react";
import { Select, Table } from "antd";
import { Link } from "react-router-dom";
import { formatRupiah, getFileNameFromUrl, titleCase } from "src/util/Formater";
import { IOrderModel } from "src/type-interface/model";

const CellMapping: any = {
    jabatan: (val: any, row: any, idx: number, order: IOrderModel): any => {
        let options: Array<any> = [];
        if (order.description === "PT (Perseroan Terbatas)") {
            options = [
                { label: "Bukan Pengurus", value: "Bukan Pengurus" },
                { label: "Direktur Utama", value: "Direktur Utama" },
                { label: "Direktur", value: "Direktur" },
                { label: "Komisaris", value: "Komisaris" },
            ];
        } else if (order.description === "CV (Commanditaire Vennootschap)") {
            options = [
                { label: "Sekutu Aktif", value: "Sekutu Aktif" },
                { label: "Sekutu Pasif", value: "Sekutu Pasif" }
            ]
        } else if (order.description === "PT Perseorangan") {
            options = [
                { label: "1", value: "1" },
                { label: "2", value: "2" }
            ]
        }
        return (
            <Select
                value={val}
                dropdownMatchSelectWidth={true}
                style={{ width: "100%" }}
                options={options} />
        );
    }
};

const RenderCell = (key: string, val: any, row: any, idx: number, order: IOrderModel) => {
    // if (typeof val === "number" && val > 10) {
    if (typeof val === "number" && String(val < 0 ? val * -1 : val).length > 2) {
        val = formatRupiah(val);
    }

    if (key.includes("download")) {
        // if (!["http://", "https://", "www."].includes(val)) {
        //     val = GetAppConfig().baseApiOrigin + val;
        // }
        return (
            ["http://", "https://", "www."].includes(val) ?
            <a href={val} rel="noopener noreferrer" target="_blank" download={getFileNameFromUrl(val)} className="ant-btn ant-btn-primary">Download</a> :
            <Link to={{ pathname: val }} target="_blank" download={getFileNameFromUrl(val)} className="ant-btn ant-btn-primary">Download</Link>
        );
    } else if (CellMapping[key]) {
        return CellMapping[key](val, row, idx, order);
    } else {
        return <span>{val}</span>;
    }
}

export interface GeneralTableViewProps {
    order: IOrderModel;
    data: Array<any>;
    title: string;
    hideColumns: Array<string>;
    onChangeValue: (grupData: string, key: string, value: any, index?: number) => void;
}

export const GeneralTableView: React.FC<GeneralTableViewProps> = (props) => {

    const { order, data, title, hideColumns } = props;

    const renderColumns = React.useCallback(() => {
        let columns: Array<any> = [];
        if (data.length > 0) {
            columns = Object.keys(data[0]).filter((key) => !hideColumns.includes(key))
            .map((key: string) => {
                return {
                    title: titleCase(key),
                    dataIndex: key,
                    key: key,
                    render: (val: any, row: any, idx: number) => RenderCell(key, val, row, idx, order)
                };
            });
        }
        return columns;
    }, [data]);

    return (
        <Table
            id="table-pemegang-saham"
            key="table-pemegang-saham"
            style={{ marginTop: 24 }}
            bordered={true}
            title={() => <h3 style={{ fontWeight: 400, margin: 0 }}>{title}</h3>}
            pagination={false}
            columns={renderColumns()}
            dataSource={data}
        // columns={[
        //     {
        //         title: "JABATAN",
        //         dataIndex: "jabatan",
        //         key: "jabatan",
        //         width: 170,
        //         render: (val: string) => <Select
        //             value={val}
        //             dropdownMatchSelectWidth={true}
        //             style={{ width: "100%" }}
        //             options={[
        //                 { label: "Bukan Pengurus", value: "Bukan Pengurus" },
        //                 { label: "Direktur Utama", value: "Direktur Utama" },
        //                 { label: "Direktur", value: "Direktur" },
        //                 { label: "Komisaris", value: "Komisaris" },
        //             ]} />,
        //     },
        //     {
        //         title: "KTP",
        //         dataIndex: "ktpName",
        //         key: "ktpName",
        //         render: (val: string, row: IUserTableView, index: number) => (
        //             <>
        //                 {
        //                     row.ktpName ?
        //                         <div style={{ display: "flex", flexDirection: "column" }}>
        //                             <Image src={`/upload/ktp/${row.idUser}/${val}`} wrapperStyle={{ border: "1px solid #e8e8e8", marginBottom: 8, height: 80 }} />
        //                             <Link to={`/upload/ktp/${row.idUser}/${val}`} target="_blank" download={val} className="ant-btn ant-btn-primary">Download</Link>
        //                         </div> :
        //                         <Upload
        //                             action=""
        //                             key={`pemegang-saham-ktp-${index}`}
        //                             id={`pemegang-saham-ktp-${index}`}
        //                             type="select" multiple={false}
        //                             accept="image"
        //                             // beforeUpload={(file) => onChangeTableView("ktp_file", file, index)}
        //                             onChange={(info) => {
        //                                 console.log("XXXX => info.file.status", info.file.status);
        //                                 if (info.file.status !== "uploading") {
        //                                     onChangeValue("dataTableView", "ktp_file", info.file.originFileObj, index);
        //                                 }
        //                             }}
        //                             showUploadList={false}>
        //                             <Button style={{ margin: 0 }}>
        //                                 <UploadOutlined /> {!row.ktp_file ? "Pilih" : "Ganti"}
        //                             </Button>
        //                         </Upload>
        //                 }
        //             </>
        //         )
        //     },
        //     {
        //         title: "NPWP",
        //         dataIndex: "npwpName",
        //         key: "npwpName",
        //         render: (val: string, row: IUserTableView, index: number) => (
        //             <>
        //                 {
        //                     row.npwpName ?
        //                         <div style={{ display: "flex", flexDirection: "column" }}>
        //                             <Image src={`/upload/npwp/${row.idUser}/${val}`} wrapperStyle={{ border: "1px solid #e8e8e8", marginBottom: 8, height: 80 }} />
        //                             <Link to={`/upload/npwp/${row.idUser}/${val}`} target="_blank" download={val} className="ant-btn ant-btn-primary">Download</Link>
        //                         </div> :
        //                         <Upload
        //                             action=""
        //                             key={`pemegang-saham-npwp-${index}`}
        //                             id={`pemegang-saham-npwp-${index}`}
        //                             type="select" multiple={false}
        //                             accept="image"
        //                             // beforeUpload={(file) => onChangeTableView("npwp_file", file, index)}
        //                             onChange={(info) => {
        //                                 if (info.file.status !== "uploading") {
        //                                     onChangeValue("dataTableView", "npwp_file", info.file.originFileObj, index);
        //                                 }
        //                             }}
        //                             showUploadList={false}>
        //                             <Button style={{ margin: 0 }}>
        //                                 <UploadOutlined /> {!row.npwp_file ? "Pilih" : "Ganti"}
        //                             </Button>
        //                         </Upload>
        //                 }
        //             </>
        //         )
        //     },
        //     // {
        //     //   title: "",
        //     //   dataIndex: "id",
        //     //   key: "action",
        //     //   width: 40,
        //     //   render: (val: string, _row, index) => <Button type="text" style={{ margin: 0 }} icon={<DeleteFilled color="#eb2f96" />} onClick={(e) => onDeleteTableView(index)} />
        //     // }
        // ]}
        // footer={() => <div style={{ width: "100%", textAlign: "center" }}>
        //   <Button type="primary" style={{ margin: 0 }} onClick={onAddTableView} icon={<PlusOutlined />}> Tambah Pemegang Saham</Button>
        // </div>}
        />
    );
}