import React from "react";

export const useCustomDebounce = (delay: number): { debouncedValue: any, onSetValue: (key: string, value: any) => void } => {
    const [debouncedValue, setDebouncedValue] = React.useState<{ [key: string]: any }>({});
    const lastValue = React.useRef<{ [key: string]: any }>({});
    const handlers = React.useRef<{ [key: string]: any }>({});

    const onSetValue = React.useCallback((key: string, value: any) => {
        lastValue.current = { ...lastValue.current, ...{ [key]: value } };
        clearTimeout(handlers.current[key]);
        handlers.current[key] = setTimeout(() => {
            setDebouncedValue({ [key]: lastValue.current[key] });
        }, delay);

        return () => {
            Object.keys(handlers.current).forEach(key => clearTimeout(handlers.current[key]));
        };
    }, [debouncedValue]);

    return {
        debouncedValue,
        onSetValue
    };
}
